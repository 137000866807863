import React, { useState, useContext, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  Navbar,
  EmailNotVerifiedNotification,
  Modal,
  AccountDeleted,
  CookieBanner,
  ChangeCookieSettings,
  PrivacyButton
} from 'components';
import { LocalContext } from 'context';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { FirebaseContext, useAuth } from 'firebase';
import '../../assets/fonts/fonts.css';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

import { defaultColors, breakpoints } from 'styles';

const GlobalStyles = createGlobalStyle`
:root {
  --primary-color: #1A2E59;
  --secondary-color: #007FC0;
  --tertiary-color: #A58A4B;
}
  * {
      margin:0;
      padding:0;
      box-sizing:border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html{
    font-size: ${({ font }) => font};
  }

  body {
    background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
    overflow-x:hidden;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  div [class*="PageWrapper"] {
    background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
  }

  a{
    text-decoration: none;
  }

  button{
    border:none;
    outline:none;
    &:focus{
        outline:none;
        border:none;
    }
  }
  .scroll-lock {
    overflow: hidden;
  }

  /* Address autocomplete dropdown style overrides */
  .pac-container {
    background: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.primary : 'var(--primary-color)'};
    border: 0.063rem solid #fff;
    border-top-width: 0;
    border-radius: 0;
    box-shadow: none;

    @media screen and (min-width: 1439px) {
      max-width: 427px !important;
      width: 100% !important;
    }
  }

.pac-target-input {
  outline: none;
  width: 100%;
}

.pac-target-input:focus {
  border: 0.188rem solid #1A2E59 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.pac-item {
  color: #fff;
  background-color: transparent;
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.031rem;
  padding: 0.188rem 1.25rem 0;
  border: none;
  height: 2.3rem;
}

.pac-item:hover {
  background-color: var(--tertiary-color);
  cursor: pointer;
}

.pac-logo {
  box-sizing: content-box;
  border: 0.188rem solid #1A2E59;
  border-top: none;

  @media screen and (min-width: 1440px) {
    box-sizing: border-box;
  }
}

.pac-logo:after {
  padding: 0;
  height: 0;
}

.hdpi.pac-logo:after {
  padding: 0;
  height: 0;
}

.pac-icon,
.hdpi .pac-icon {
  display: none;
}

.pac-item-query {
  color: #fff;
  font-size: 1rem;
}

.pac-item-query:after {
  content: ','
}

.pac-selected {
  background-color: ${({ selectedEvent }) =>
    selectedEvent ? selectedEvent.colors.tertiary : 'var(--secondary-color)'};
}
`;

const Wrapper = styled.div`
  box-sizing: border-box;
`;

const Layout = ({ children }) => {
  const {
    theme,
    fontSizing,
    selectedEvent,
    aNewUserHasRegisteredForAnEvent,
    showUserAccountDeletedModal,
    setShowUserAccountDeletedModal,
    showCookieBarModal,
    setShowCookieBarModal
  } = useContext(LocalContext);
  const [
    returningUserHasNotConfirmedTheirEmailAddressYet,
    setReturningUserHasNotConfirmedTheirEmailAddressYet
  ] = useState(false);
  const [newUserLocalStorageData, setNewUserLocalStorageData] = useState(null);
  const [showChangeSettingsModal, setShowChangeSettingsModal] = useState(false);
  const { user, firebase, loading } = useAuth();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { mode } = queryParams;
  const colors = selectedEvent ? selectedEvent.colors : defaultColors;

  // newUserLocalStorageData is cleared from the browser's local storage as soon as their 'user' doc
  // is first created in the database, so if we can still get 'newUserIPHAForum' from local
  // storage below, then we know they haven't clicked the link yet. We then pass that set
  // newUserLocalStorageData as state, which triggers the second useEffect, which in turn mounts
  // the EmailNotVerifiedNotification component again, which re-sends the email and notifies the user.

  useEffect(() => {
    if (window.localStorage.getItem('googleAnalyticsAccepted') === null) {
      setShowCookieBarModal(true);
    }
  }, [loading, firebase]);

  useEffect(() => {
    if (!loading && firebase) {
      const data = JSON.parse(window.localStorage.getItem('newUserIPHAForum'));
      if (!firebase?.auth?.currentUser && data && !mode) {
        setNewUserLocalStorageData(data);
      }
    }
    return () => {
      // This is just for safety, though hopefully not necessary.
      if (firebase?.auth?.currentUser) {
        window.localStorage.removeItem('newUserIPHAForum');
        window.localStorage.removeItem('existingUserIPHAForum');
      }
    };
  }, [loading, firebase]);

  useEffect(() => {
    if (newUserLocalStorageData) {
      setReturningUserHasNotConfirmedTheirEmailAddressYet(true);
    }
  }, [newUserLocalStorageData]);

  useEffect(() => {
    let unsubscribeToRTDBServer;

    // Sometimes the user object can initialise briefly with the user data from Firebase Auth but
    // not yet the user data from Firestore, so to prevent any errors here we check for the uid
    // property on the user object, to make sure it has the Firestore data. If we don't do this then
    // the code may try to update 'presence' on a document that doesn't exist yet, resulting in an
    // error.
    if (!loading && firebase && user?.uid) {
      unsubscribeToRTDBServer = firebase.interaction.participants.subscribeToRTDBServer({
        snapshot: (snapshot) => {
          if (snapshot.val()) {
            return firebase.interaction.participants.updateUserPresence({
              user,
              eid: selectedEvent ? selectedEvent.eid : ''
            });
          }
        }
      });
    }
    return () => {
      if (unsubscribeToRTDBServer) {
        unsubscribeToRTDBServer();
      }
    };
    // We're using user?.uid in the dependency array here - instead of just user - to avoid getting
    // into an infinite loop each time presence is updated on the user object.
  }, [loading, firebase, user?.uid, selectedEvent?.eid]);

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <Wrapper $theme={theme}>
        <GlobalStyles $theme={theme} font={fontSizing} selectedEvent={selectedEvent} />
        <Navbar colors={colors} />
        {children}
        {!mode &&
          (aNewUserHasRegisteredForAnEvent || returningUserHasNotConfirmedTheirEmailAddressYet) && (
            <EmailNotVerifiedNotification
              colors={colors}
              newUserLocalStorageData={newUserLocalStorageData}
              returningUserHasNotConfirmedTheirEmailAddressYet={
                returningUserHasNotConfirmedTheirEmailAddressYet
              }
            />
          )}
        <Modal
          hideCloseButton
          openWithoutTrigger={showUserAccountDeletedModal}
          modalContent={
            <AccountDeleted
              setShowUserAccountDeletedModal={setShowUserAccountDeletedModal}
              onClickOutside={() => setShowUserAccountDeletedModal(false)}
            />
          }
        />
        <Modal
          hideCloseButton
          disableCloseOnClickOutside
          openWithoutTrigger={showCookieBarModal}
          modalContent={
            <CookieBanner
              setShowCookieBarModal={setShowCookieBarModal}
              setShowChangeSettingsModal={setShowChangeSettingsModal}
            />
          }
        />
        <Modal
          hideCloseButton
          disableCloseOnClickOutside
          openWithoutTrigger={showChangeSettingsModal}
          modalContent={
            <ChangeCookieSettings
              setShowChangeSettingsModal={setShowChangeSettingsModal}
              setShowCookieBarModal={setShowCookieBarModal}
            />
          }
        />
      </Wrapper>
    </FirebaseContext.Provider>
  );
};

export default Layout;
