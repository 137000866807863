import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  onSnapshot
} from 'firebase/firestore';
import {
  ref,
  update,
  onDisconnect,
  onValue,
  serverTimestamp as rtdbServerTimestamp
} from 'firebase/database';

function ParticipantFactory(firebase) {
  const fetchAllEventParticipants = async ({ eid, lastFetchedParticipantDoc, limitFetch }) => {
    let fetchParticipantsQuery;
    if (limitFetch) {
      if (lastFetchedParticipantDoc) {
        fetchParticipantsQuery = query(
          collection(firebase.fsdb, 'users'),
          where('eventsUserCanAccess', 'array-contains', eid),
          orderBy('name'),
          startAfter(lastFetchedParticipantDoc),
          limit(20)
        );
      } else {
        fetchParticipantsQuery = query(
          collection(firebase.fsdb, 'users'),
          where('eventsUserCanAccess', 'array-contains', eid),
          orderBy('name'),
          limit(20)
        );
      }
    } else {
      fetchParticipantsQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid),
        orderBy('name')
      );
    }
    return getDocs(fetchParticipantsQuery);
  };

  const fetchPaginatedParticipants = async ({
    eid,
    lastFetchedCurrentlyParticipatingUserDoc,
    lastFetchedNotCurrentlyParticipatingUserDoc
  }) => {
    let currentlyParticipatingUsersQuery;
    let notCurrentlyParticipatingUsersQuery;

    if (lastFetchedCurrentlyParticipatingUserDoc) {
      currentlyParticipatingUsersQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid),
        where('presence.selectedEventId', '==', eid),
        orderBy('name'),
        startAfter(lastFetchedCurrentlyParticipatingUserDoc),
        limit(20)
      );
    } else {
      currentlyParticipatingUsersQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid),
        where('presence.selectedEventId', '==', eid),
        orderBy('name'),
        limit(20)
      );
    }

    if (lastFetchedNotCurrentlyParticipatingUserDoc) {
      notCurrentlyParticipatingUsersQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid),
        where('presence.selectedEventId', '!=', eid),
        orderBy('presence.selectedEventId'),
        orderBy('name'),
        startAfter(lastFetchedNotCurrentlyParticipatingUserDoc),
        limit(20)
      );
    } else {
      notCurrentlyParticipatingUsersQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid),
        where('presence.selectedEventId', '!=', eid),
        orderBy('presence.selectedEventId'),
        orderBy('name'),
        limit(20)
      );
    }

    const docs = [];

    docs.push(getDocs(currentlyParticipatingUsersQuery));
    docs.push(getDocs(notCurrentlyParticipatingUsersQuery));

    return Promise.all(docs);
  };

  const updateUserPresence = async ({ user, eid }) => {
    const userPresenceRTDBRef = ref(firebase.rtdb, `/presence/${user.uid}`);
    return onDisconnect(userPresenceRTDBRef)
      .set({
        selectedEventId: '',
        lastChanged: rtdbServerTimestamp(),
        lastRefreshAt: user.lastRefreshAt
      })
      .then(() => {
        update(userPresenceRTDBRef, {
          selectedEventId: eid,
          lastChanged: rtdbServerTimestamp(),
          lastRefreshAt: user.lastRefreshAt
        });
      });
  };

  const subscribeToAllEventParticipantsPresent = ({ eid, snapshot }) =>
    onSnapshot(
      query(collection(firebase.fsdb, 'users'), where('presence.selectedEventId', '==', eid)),
      snapshot
    );

  const subscribeToRTDBServer = ({ snapshot }) =>
    onValue(ref(firebase.rtdb, '.info/connected'), snapshot);

  return {
    fetchAllEventParticipants,
    fetchPaginatedParticipants,
    updateUserPresence,
    subscribeToAllEventParticipantsPresent,
    subscribeToRTDBServer
  };
}

export default ParticipantFactory;
