import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { StaticImage } from 'gatsby-plugin-image';
import { breakpoints } from 'styles';

const HubHeaderBackground = ({ colors }) => {
  const { theme } = useContext(LocalContext);

  return (
    <Background $theme={theme} colors={colors}>
      <StaticImage
        src="../../../../assets/images/hub-background.png"
        formats={['auto', 'webp', 'avif']}
        placeholder="blurred"
        alt="IPHA Hub"
        quality={90}
      />
    </Background>
  );
};

const Background = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  .gatsby-image-wrapper {
    display: none;
  }

  ${breakpoints.lg} {
    .gatsby-image-wrapper {
      bottom: 0;
      display: block;
      height: 100%;
      min-width: 600px;
      object-fit: contain !important;
      position: absolute !important;
      right: 0;
    }
  }
`;

export default HubHeaderBackground;
