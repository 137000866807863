import React, { useContext } from 'react';
import styled from 'styled-components';
import { H3, P, breakpoints } from 'styles';
import { LocalContext } from 'context';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Sponsor = () => {
  const { theme } = useContext(LocalContext);
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "assets/images/medicine-ie-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <SectionWrapper $theme={theme} id="info">
      <Content>
        <Description>
          <GatsbyImage image={getImage(data.logo)} />
          <P>
            Medicines.ie contains accurate, up-to-date, regulator-approved information on medicines
            available in Ireland. The site is a comprehensive and easily searchable source of
            medicines information in Ireland.
            <br />
            <br />
            It is widely regarded as an invaluable reference source by healthcare professionals, the
            National Medicines Information Centre and the Department of Health.
            <br />
            <br />
            Later this year medicines.ie will be launching SPCcomply, based on feedback from our
            members. SPCcomply ensures updated SmPCs are delivered to sales teams within minutes of
            being uploaded to medicines.ie. SPCcomply ensures compliance and simplifies internal
            processes and SOPs.
          </P>
        </Description>
        <VideoWrapper $theme={theme}>
          <iframe
            title="Sample"
            src="https://player.vimeo.com/video/948737881?h=c709841469"
            frameBorder="0"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          />
        </VideoWrapper>
      </Content>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: ${({ $theme }) => $theme.bg};
  padding: 3rem 1.75rem;
  width: 100%;
  p {
    color: ${({ $theme }) => $theme.textMuted};
    font-family: 'Open Sans', sans-serif;
  }
  ${breakpoints.lg} {
    padding: 6.25rem 9rem;
  }
`;

const Title = styled(H3)`
  color: ${({ $theme }) => $theme.fg};
  margin-bottom: 3rem;
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.25rem;

  ${breakpoints.lg} {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 50% 50%;
  }
`;

const VideoWrapper = styled.div`
  border: 3px solid #a58a4b;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  .gatsby-image-wrapper {
    height: auto;
    width: 100%;
    max-width: 314px;
    margin-bottom: 1.5rem;
    img {
      object-fit: contain !important;
    }
  }
  ${breakpoints.sm} {
    align-items: center;
    svg {
      max-width: 300px;
    }
  }
  ${breakpoints.lg} {
    align-items: start;
  }
`;

export default Sponsor;
