import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from 'context';
import styled from 'styled-components';
import AnalyticsBar from '../../AnalyticsBar';

function LivestreamAndInteractions({ eid, eventRegistrants }) {
  const [eventPresentParticipants, setEventPresentParticipants] = useState(0);
  const [eventComments, setEventComments] = useState(0);
  const [eventQuestions, setEventQuestions] = useState(0);
  const [eventEmojiReactions, setEventEmojiReactions] = useState(0);
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    let unsubscribeFromEventComments;
    let unsubscribeFromEventQuestions;
    let unsubscribeFromEventParticipantsOnline;
    let unsubscribeFromEventEmojis;
    if (firebase) {
      unsubscribeFromEventParticipantsOnline =
        firebase.interaction.participants.subscribeToAllEventParticipantsPresent({
          eid,
          snapshot: (snapshot) => {
            if (!snapshot.empty) {
              setEventPresentParticipants(snapshot.size);
            } else {
              setEventPresentParticipants(0);
            }
          }
        });
      unsubscribeFromEventComments = firebase.interaction.chat.subscribeToEventComments({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventComments(snapshot.size);
          } else {
            setEventComments(0);
          }
        }
      });
      unsubscribeFromEventQuestions = firebase.interaction.qAndA.subscribeToEventQuestions({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventQuestions(snapshot.size);
          } else {
            setEventQuestions(0);
          }
        }
      });
      unsubscribeFromEventEmojis = firebase.interaction.emojis.subscribeToEventEmojis({
        eid,
        snapshot: (snapshot) => {
          if (snapshot.exists) {
            setEventEmojiReactions(snapshot.size);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventParticipantsOnline) {
        unsubscribeFromEventParticipantsOnline();
      }
      if (unsubscribeFromEventComments) {
        unsubscribeFromEventComments();
      }
      if (unsubscribeFromEventQuestions) {
        unsubscribeFromEventQuestions();
      }
      if (unsubscribeFromEventEmojis) {
        unsubscribeFromEventEmojis();
      }
    };
  }, [firebase]);

  return (
    <Container>
      <AnalyticsBar title="Registrants" padding="1.25rem" height="225px">
        <Analytic>
          <span>{eventRegistrants}</span>
          <p>Number of people registered</p>
        </Analytic>
        <Analytic>
          <span>{eventPresentParticipants}</span>
          <p>Number of currently active participants</p>
        </Analytic>
      </AnalyticsBar>
      <AnalyticsBar title="Interactions" padding="1.25rem" height="225px">
        <Analytic>
          <span>{eventComments}</span>
          <p>Total number of comments</p>
        </Analytic>
        <Analytic>
          <span>{eventQuestions}</span>
          <p>Total number of questions</p>
        </Analytic>
        <Analytic>
          <span>{eventEmojiReactions}</span>
          <p>Total number of emoji reactions</p>
        </Analytic>
      </AnalyticsBar>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Analytic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  span {
    font-size: 3rem !important;
    font-weight: 700;
    line-height: 3rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 200px;
    text-align: center;
  }
`;

export default LivestreamAndInteractions;
