import React, { useContext, useEffect, useState, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { H1, H5, breakpoints, Grid } from 'styles';
import { DateButton, Interaction, LivestreamOverlays } from 'components';
import Vimeo from '@vimeo/player';
import { useIntersection } from 'hooks';
import { Link } from 'gatsby';
import { LocalContext, FirebaseContext } from 'context';
import throttle from 'just-throttle';
import { Markup } from 'interweave';
import { AnimatePresence, motion } from 'framer-motion';
import defaultThumbnail from 'assets/images/event-8/social-thumbnail.png';

const Livestream = ({
  colors,
  eid,
  eventName,
  eventSlug,
  eventSubtitle,
  eventTitle,
  eventStartTime,
  eventEndTime,
  eventLocation,
  eventDescription,
  eventBanner,
  eventStaticImage,
  eventBannerVideo,
  isPollsEnabled,
  isQAndAEnabled,
  isChatEnabled,
  isParticipantsEnabled,
  endScreenImg
}) => {
  const bannerImage = getImage(eventBanner);
  const staticImage = getImage(eventStaticImage);
  const { theme, streamRef, isMobile } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [livestreamIsPlaying, setLivestreamIsPlaying] = useState(false);
  const [pipRef, vimeoPlayerObserver] = useIntersection({
    initialInView: true,
    threshold: 0.75
  });
  const [isPipModeEnabled, setIsPipModeEnabled] = useState(false);
  const [questionCurrentlyBeingAnsweredLive, setQuestionCurrentlyBeingAnsweredLive] =
    useState(null);
  const [forcedActiveTab, setForcedActiveTab] = useState(null);
  const [currentlyOpenWordCloudId, setCurrentlyOpenWordCloudId] = useState(null);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [streamUrl, setStreamUrl] = useState(null);
  const [activeTab, setActiveTab] = useState('Chat');
  const [showOnlinePresenceToUsers, setShowOnlinePresenceToUsers] = useState(false);
  console.log(user);
  const livestreamRef = useRef(null);

  useEffect(() => {
    const video = document.getElementById('bgvid');
    const slowVideo = () => {
      video.playbackRate = 0.5;
    };
    slowVideo();
  }, []);

  /* TODO: Refactor/fix this */
  // useEffect(() => {
  //   const keyDownListener = (e) => {
  //     if (e.code === 'Enter' || e.code === 'NumpadEnter') {
  //       if (activeTab === 'Chat') {
  //         handleUserCommentSubmit(e);
  //       } else if (activeTab === 'Q&A') {
  //         handleSubmitNewQuestion(e);
  //       }
  //     }
  //   };
  //   document.addEventListener('keydown', keyDownListener);
  //   return () => {
  //     document.removeEventListener('keydown', keyDownListener);
  //   };
  // }, [activeTab, userCommentText, textArea]);

  useEffect(() => {
    let unsubscribeFromEventUpdates;

    if (firebase) {
      unsubscribeFromEventUpdates = firebase.subscribeToEventUpdates({
        eid,
        snapshot: (snapshot) => {
          if (snapshot.exists) {
            const {
              forcedActiveTab: _forcedActiveTab,
              questionCurrentlyBeingAnsweredLive: _questionCurrentlyBeingAnsweredLive,
              currentlyOpenWordCloudId: _currentlyOpenWordCloudId,
              showOnlinePresenceToUsers: _showOnlinePresenceToUsers,
              streamUrl: _streamUrl,
              showEndScreen: _showEndScreen
            } = snapshot.data();

            setStreamUrl(_streamUrl);
            setShowEndScreen(_showEndScreen);
            setShowOnlinePresenceToUsers(_showOnlinePresenceToUsers);

            if (_forcedActiveTab || _forcedActiveTab === null) {
              setForcedActiveTab(_forcedActiveTab);
            }

            if (_questionCurrentlyBeingAnsweredLive) {
              setQuestionCurrentlyBeingAnsweredLive(_questionCurrentlyBeingAnsweredLive);
            } else {
              setQuestionCurrentlyBeingAnsweredLive(null);
            }

            if (_currentlyOpenWordCloudId) {
              setCurrentlyOpenWordCloudId(_currentlyOpenWordCloudId);
            } else {
              setCurrentlyOpenWordCloudId(null);
            }
          } else if (!snapshot.exists) {
            /* CONOR NOTE TO SELF: Is there a better way of doing this? Can we automatically add the event doc somehow? Hmmmm */
            console.error(
              "You need to add a doc for this event in the 'Events' collection in Firebase."
            );
          }
        }
      });
      return () => {
        if (unsubscribeFromEventUpdates) {
          unsubscribeFromEventUpdates();
        }
      };
    }
  }, [firebase, eid]);

  useEffect(() => {
    const livestreamIframe = document.getElementById('livestream-iframe');

    if (livestreamIframe && user && eid) {
      const _vimeoPlayer = new Vimeo(livestreamIframe);

      setVimeoPlayer(_vimeoPlayer);

      // I'm defining a video "session" as a discrete period of time someone is on the page watching
      // a video. If someone watches an hour one day, then navigates away and comes back to the same
      // page the next day, then those are two seperate sessions. Likewise, if they watch 20 mins
      // then navigate to another part of the site and then come back to the same page again and
      // watches another 20 mins, then those are two seperate sessions. But if someone watches 20
      // mins and pauses the video, then unpauses and continues watching, then that's a single
      // session. Likewise, if someone watches 20 mins and then fast-forwards an hour and continues
      // watching, then that's also a single session.
      const updateVideoSessionData = async () => {
        console.log('updateVideoSessionData');
        const data = await _vimeoPlayer.getPlayed();

        const timeRange = data.map((arr) => ({
          start: parseFloat(arr[0].toFixed(2)),
          end: parseFloat(arr[1].toFixed(2))
        }));

        return firebase.livestream.updateVideoSessionData({
          uid: user.uid,
          eid,
          timeRange
        });
      };

      _vimeoPlayer.on('loaded', () => {
        console.log('loaded');
        _vimeoPlayer.on('timeupdate', throttle(updateVideoSessionData, 60000));
        _vimeoPlayer.on('play', (data) => {
          // Using an 'if' statement here because when the 'play' event is fired
          // after any 'seek' event 'data' is undefined.
          if (data) {
            setLivestreamIsPlaying(true);
          }
        });
        _vimeoPlayer.on('pause', (data) => {
          setLivestreamIsPlaying(false);

          // The 'pause' event is also fired when the video ends, along with the 'ended' event.
          // We want to ignore it when the video has ended, so we'll check the percent value.
          if (data.percent !== 1) {
            updateVideoSessionData();
          }
        });
        _vimeoPlayer.on('seeked', updateVideoSessionData);
        _vimeoPlayer.on('ended', updateVideoSessionData);

        _vimeoPlayer.on('enterpictureinpicture', () => {
          setIsPipModeEnabled(true);
        });
        _vimeoPlayer.on('leavepictureinpicture', () => {
          setIsPipModeEnabled(false);
        });
      });
    }

    return () => {
      // document.removeEventListener('keydown', keyDownListener);
      vimeoPlayer?.off('timeupdate');
      vimeoPlayer?.off('play');
      vimeoPlayer?.off('pause');
      vimeoPlayer?.off('seeked');
      vimeoPlayer?.off('ended');
      vimeoPlayer?.off('enterpictureinpicture');
      vimeoPlayer?.off('leavepictureinpicture');
    };
  }, [user, eid]);

  useEffect(() => {
    if (
      document.pictureInPictureEnabled &&
      vimeoPlayer &&
      !vimeoPlayer.disablePictureInPicture &&
      livestreamIsPlaying &&
      vimeoPlayerObserver &&
      !vimeoPlayerObserver.isIntersecting
    ) {
      try {
        vimeoPlayer.requestPictureInPicture();
      } catch (err) {
        console.error(err);
      }
    }
  }, [livestreamIsPlaying, vimeoPlayer, vimeoPlayerObserver]);

  const handlePipOverlayClick = () => vimeoPlayer.exitPictureInPicture();

  return (
    <Container colors={colors}>
      <Banner>
        {eventBannerVideo ? (
          <>
            <BannerVideo playsInline autoPlay muted loop id="bgvid">
              <source src={eventBannerVideo} type="video/mp4" />
            </BannerVideo>
            <StaticBannerImage
              image={staticImage}
              event={eid}
              alt={`${eventName} Stream Placeholder`}
            />
          </>
        ) : (
          <BannerImage image={bannerImage} alt={`${eventName} Stream Placeholder`} />
        )}
      </Banner>
      <Details>
        <div ref={streamRef} style={{ position: 'absolute', top: '-4.25rem' }} />
        {eid === '2' && (
          <Subtitle $theme={theme}>
            Explore the diversity of career options in the biopharmaceutical industry
          </Subtitle>
        )}
        <Title $theme={theme} event={eid}>
          <Markup content={eventName} noHtml />
        </Title>
      </Details>
      <LivestreamAndInteraction key={eid}>
        <LiveStream ref={livestreamRef} id="livestream">
          {showEndScreen && endScreenImg ? (
            <div
              style={{
                border: `0.188rem solid`,
                borderColor: colors.primary,
                transition: 'border-color',
                transitionDuration: '0.5s',
                padding: '56.25% 0 0 0',
                height: '100%',
                position: 'relative',
                overflow: 'hidden'
              }}>
              <GatsbyImage
                image={getImage(endScreenImg)}
                alt={`${eventName} Stream Placeholder`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
            </div>
          ) : (
            <div
              ref={pipRef}
              style={{
                padding: '56.25% 0 0 0',
                height: '100%',
                position: 'relative',
                overflow: 'hidden'
              }}>
              {streamUrl ? (
                <iframe
                  title={eventName}
                  id="livestream-iframe"
                  src={streamUrl}
                  frameBorder="0"
                  referrerPolicy="strict-origin"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                />
              ) : (
                <PlaceholderImg src={defaultThumbnail} alt="placeholder" />
              )}
              <LivestreamOverlays
                colors={colors}
                eid={eid}
                handlePipOverlayClick={handlePipOverlayClick}
                isPipModeEnabled={isPipModeEnabled}
                livestreamRef={livestreamRef}
                questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
                currentlyOpenWordCloudId={currentlyOpenWordCloudId}
              />
            </div>
          )}
        </LiveStream>
        <Interaction
          colors={colors}
          eid={eid}
          forcedActiveTab={forcedActiveTab}
          questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
          isPollsEnabled={isPollsEnabled}
          isQAndAEnabled={isQAndAEnabled}
          isChatEnabled={isChatEnabled}
          isParticipantsEnabled={isParticipantsEnabled}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          showOnlinePresenceToUsers={showOnlinePresenceToUsers}
        />
      </LivestreamAndInteraction>
      <Grid>
        <StreamLinks>
          <DateButton
            eventName={eventTitle}
            eventSlug={eventSlug}
            eventDescription={eventDescription}
            eventLocation={eventLocation}
            eventEndTime={eventEndTime}
            eventStartTime={eventStartTime}
            colors={colors}
          />
        </StreamLinks>
        {activeTab === 'Q&A' && user?.isModerator?.includes(eid) && !isMobile && (
          <HelpLink to="/help-hub/q-and-a">Q&A Moderator Guidelines</HelpLink>
        )}
        {activeTab === 'Polls' && user?.isModerator?.includes(eid) && !isMobile && (
          <HelpLink to="/help-hub/polls">Polls Moderator Guidelines</HelpLink>
        )}
      </Grid>
    </Container>
  );
};

// const Dates = styled(H5)`
//   color: ${({ colors, $theme }) => ($theme.className === 'contrast' ? $theme.fg : colors.primary)};
//   margin-bottom: 0;
// `;

const PlaceholderImg = styled.img`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Title = styled(H1)`
  color: ${({ $theme }) => $theme.contrastTextColor};
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  margin-bottom: 0;
  padding-left: 0;
  ${breakpoints.lg} {
    font-size: 3.125rem;
    line-height: 3.125rem;
    max-width: 900px;
  }
`;

const Subtitle = styled(H5)`
  color: ${({ $theme }) => $theme.fg};
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 0;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin-top: 1.5rem;
  position: relative;
  z-index: 2;
  ${breakpoints.sm} {
    grid-column: 2/5;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  ${breakpoints.lg} {
    grid-column: 2/12;
    margin-bottom: 2rem;
    margin-left: -1rem;
    margin-top: 1.5rem;
  }
`;

const StreamLinks = styled.div`
  grid-column: 1/5;
  margin-top: 1.5rem;
  ${breakpoints.sm} {
    grid-column: 2/5;
    margin: 0.25rem 0 1.125rem;
  }
  ${breakpoints.md} {
    grid-column: 2/5;
    justify-self: end;
    margin: 0.25rem 0 1.125rem;
  }
  ${breakpoints.lg} {
    grid-column: 2/5;
    justify-self: start;
  }
`;

const HelpLink = styled(Link)`
  color: #a58a4b !important;
  font-size: 0.75rem;
  grid-column: 7/13;
  justify-self: end;
  text-decoration: underline;
  text-underline-position: under;
  z-index: 2;
`;

const LiveStream = styled.div`
  border: 3px solid #a58a4b;
  height: auto;
  margin: 1.25rem -0.75rem;
  overflow: hidden;
  position: relative;
  z-index: 2;
  ${breakpoints.sm} {
    margin: 1.25rem 0;
  }
  ${breakpoints.lg} {
    height: 100%;
    margin: 0px;
  }
`;

const BannerImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
`;

const StaticBannerImage = styled(GatsbyImage)`
  height: 100%;
  position: absolute;
  right: ${({ event }) => (event === '4' ? '-225px !important' : '0 !important')};
  top: ${({ event }) => (event === '1' ? '-75px' : event === '4' ? '-50px' : '0')};
  width: 100%;
  z-index: 2;
  img {
    object-position: ${({ event }) => (event === '9' ? 'right top' : 'right bottom')};
  }
`;

const BannerVideo = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Banner = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 0;
`;

const LivestreamAndInteraction = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: { opacity: 0 }
})`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  width: 100%;
  ${breakpoints.sm} {
    grid-column: 2/6;
  }
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-template-columns: 2fr 1fr;
  }
`;

const Container = styled.div`
  color: #00004e;
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  height: auto;
  margin: 0 auto;
  padding: 1.25rem 1rem;
  position: relative;
  width: 100%;
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 1.25rem 1.25rem 0;
  }
`;

export default Livestream;
