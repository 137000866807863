import * as React from 'react';

function SvgBgMoon1(props) {
  return (
    <svg viewBox="0 0 201 218" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M182.833 41.6981L118.16 4.70153C112.79 1.62167 106.696 0 100.492 0C94.288 0 88.1938 1.62167 82.824 4.70153L18.1119 41.6981C12.7475 44.7704 8.29177 49.1861 5.19097 54.5028C2.09017 59.8195 0.453214 65.8507 0.444092 71.992V146.004C0.449353 152.146 2.08465 158.179 5.18584 163.496C8.28703 168.813 12.7449 173.228 18.1119 176.298L82.7666 213.313C88.1396 216.384 94.2325 218 100.434 218C106.636 218 112.729 216.384 118.102 213.313L182.776 176.298C188.141 173.225 192.596 168.81 195.697 163.493C198.798 158.176 200.435 152.145 200.444 146.004V72.0487C200.447 65.9041 198.822 59.8666 195.731 54.5401C192.641 49.2137 188.193 44.7853 182.833 41.6981Z"
        fill="#A58A4B"
      />
    </svg>
  );
}

export default SvgBgMoon1;
