import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Participant } from 'components';
import { FirebaseContext } from 'context';
import { motion } from 'framer-motion';

const Participants = ({
  colors,
  eid,
  handleParticipantAvatarOrNameClick,
  showOnlinePresenceToUsers
}) => {
  const { firebase, user } = useContext(FirebaseContext);
  const particpantsContainerRef = useRef();
  const [lastFetchedParticipantDoc, setLastFetchedParticipantDoc] = useState(null);
  const [lastFetchedCurrentlyParticipatingUserDoc, setLastFetchedCurrentlyParticipatingUserDoc] =
    useState(null);

  const [
    lastFetchedNotCurrentlyParticipatingUserDoc,
    setLastFetchedNotCurrentlyParticipatingUserDoc
  ] = useState(null);
  const [participants, setParticipants] = useState([]);

  const populateEventParticipantsList = (result) => {
    if (!result.empty) {
      setParticipants((currrentValue) => [
        ...currrentValue,
        ...result.docs.map((doc) => {
          const { name, profession, company, socials, avatarUrl, eventRole, presence, uid } =
            doc.data();
          return {
            name,
            profession,
            company,
            socials,
            avatarUrl,
            eventRole,
            presence,
            uid
          };
        })
      ]);
      setLastFetchedParticipantDoc(result.docs[result.docs.length - 1]);
    }
  };

  const populatePaginatedParticipantsList = async (snapshots) => {
    const nextParticipants = [];
    const [currentlyParticipatingUsersSnapshot, notCurrentlyParticipatingUsersSnapshot] = snapshots;

    const userHasNotBeenAddedToTheParticipantsListAlready = (uid) =>
      !participants.some((participant) => participant.uid === uid);

    if (!currentlyParticipatingUsersSnapshot?.empty) {
      currentlyParticipatingUsersSnapshot.docs.forEach((doc) => {
        const { uid } = doc.data();
        if (userHasNotBeenAddedToTheParticipantsListAlready(uid)) {
          nextParticipants.push(doc);
        }
      });
    }

    if (
      currentlyParticipatingUsersSnapshot?.empty &&
      !notCurrentlyParticipatingUsersSnapshot?.empty
    ) {
      notCurrentlyParticipatingUsersSnapshot.docs.forEach((doc) => {
        const { uid } = doc.data();
        if (userHasNotBeenAddedToTheParticipantsListAlready(uid)) {
          nextParticipants.push(doc);
        }
      });
    }

    if (nextParticipants.length) {
      setParticipants((currentParticipants) => [
        ...currentParticipants,
        ...nextParticipants.map((doc) => {
          const { name, profession, company, socials, avatarUrl, eventRole, presence, uid } =
            doc.data();
          return {
            name,
            profession,
            company,
            socials,
            avatarUrl,
            eventRole,
            presence,
            uid
          };
        })
      ]);

      if (!currentlyParticipatingUsersSnapshot?.empty) {
        setLastFetchedCurrentlyParticipatingUserDoc(
          currentlyParticipatingUsersSnapshot.docs[
            currentlyParticipatingUsersSnapshot.docs.length - 1
          ]
        );
      }

      if (
        currentlyParticipatingUsersSnapshot?.empty &&
        !notCurrentlyParticipatingUsersSnapshot?.empty
      ) {
        setLastFetchedNotCurrentlyParticipatingUserDoc(
          notCurrentlyParticipatingUsersSnapshot.docs[
            notCurrentlyParticipatingUsersSnapshot.docs.length - 1
          ]
        );
      }
    }
  };

  const fetchPaginatedParticipants = () => {
    if (!user) {
      firebase.interaction.participants
        .fetchAllEventParticipants({ eid, lastFetchedParticipantDoc, limitFetch: true })
        .then(populateEventParticipantsList);
    } else if (user && (user.isAdmin || showOnlinePresenceToUsers)) {
      firebase.interaction.participants
        .fetchPaginatedParticipants({
          eid,
          lastFetchedCurrentlyParticipatingUserDoc,
          lastFetchedNotCurrentlyParticipatingUserDoc
        })
        .then(populatePaginatedParticipantsList);
    } else {
      firebase.interaction.participants
        .fetchAllEventParticipants({ eid, lastFetchedParticipantDoc, limitFetch: true })
        .then(populateEventParticipantsList);
    }
  };

  useEffect(() => {
    if (!user) {
      firebase.interaction.participants
        .fetchAllEventParticipants({ eid, limitFetch: true })
        .then(populateEventParticipantsList);
    } else if (user && (user.isAdmin || showOnlinePresenceToUsers)) {
      firebase.interaction.participants
        .fetchPaginatedParticipants({
          eid,
          lastFetchedCurrentlyParticipatingUserDoc,
          lastFetchedNotCurrentlyParticipatingUserDoc
        })
        .then(populatePaginatedParticipantsList);
    } else {
      firebase.interaction.participants
        .fetchAllEventParticipants({ eid, limitFetch: true })
        .then(populateEventParticipantsList);
    }

    return () => {
      setParticipants([]);
      setLastFetchedParticipantDoc(null);
      setLastFetchedCurrentlyParticipatingUserDoc(null);
      setLastFetchedNotCurrentlyParticipatingUserDoc(null);
    };
  }, []);

  const duplicateUsers = participants
    .map(({ uid }) => uid)
    .filter((item, index) => index !== participants.map(({ uid }) => uid).indexOf(item));

  if (duplicateUsers.length) {
    console.warn('Duplicate users: ', duplicateUsers);
  }

  return (
    <Wrapper ref={particpantsContainerRef} colors={colors} tabIndex={0}>
      {participants.length > 0 &&
        participants.map((participant, i, arr) => (
          <Participant
            key={participant.uid}
            eid={eid}
            participant={participant}
            fetchPaginatedParticipants={fetchPaginatedParticipants}
            handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            isLastFetchedParticipant={arr.length === i + 1}
            showOnlinePresenceToUsers={user && (user.isAdmin || showOnlinePresenceToUsers)}
            colors={colors}
            particpantsContainerRef={particpantsContainerRef}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 1.25rem;
  min-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

export default Participants;
