import * as React from 'react';
import { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { DownArrow } from 'assets/svgs';
import { breakpoints, defaultColors } from 'styles';
import { hexToRGB } from 'utils';
import Answer from './Answer';

const faqs = [
  {
    question: 'How do I register for an #IPHAForum event?',
    answer:
      'Simply fill out the registration form and you will be sent a verification email to complete the registration.'
  },
  {
    question: 'How do I view the livestream on the day of event?',
    answer:
      'You will be sent a reminder email on how to access the event. Alternatively, you can visit www.iphaforum.ie and you will be directed to the event page.'
  },
  {
    question: 'Help! I can’t find my verification email.',
    answer:
      'Please check your junk mail or contact your IT department if the email is not in your inbox or junk. Alternatively, you can drop us an email at support@ipha.ie and we’ll be able to assist you.'
  },
  {
    question: 'Can I to interact with registered guests and panelists?',
    answer:
      'Yes, you can participate in the public chat or send in your questions to our panelists via our Q&A tab on the platform. You can react via emojis during the live event.'
  }
];

const Accordion = ({ i, expanded, setExpanded, question, answer }) => {
  const isOpen = i === expanded;
  const { theme } = useContext(LocalContext);

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <div style={{ marginBottom: '0.625rem' }}>
      <Question $theme={theme} onClick={() => setExpanded(isOpen ? false : i)} $expanded={isOpen}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {question}
          <DownArrow />
        </div>
      </Question>
      <AnimatePresence initial={false}>
        {isOpen && (
          <AnswerText
            $theme={theme}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.375, ease: [0.04, 0.62, 0.23, 0.98] }}>
            <Answer answer={answer} />
          </AnswerText>
        )}
      </AnimatePresence>
    </div>
  );
};

const Faq = () => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(false);

  return faqs.map((faq, i) => (
    <Accordion
      i={i}
      key={i}
      expanded={expanded}
      setExpanded={setExpanded}
      question={faq.question}
      answer={faq.answer}
    />
  ));
};

const Question = styled(motion.div)`
  background: ${({ $theme }) => ($theme.className === 'contrast' ? $theme.bgLight : '#f9f9f9')};
  color: ${({ $theme }) => $theme.textMuted};
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
  grid-column: 1/7;
  line-height: 1.75rem;
  padding: 1.5rem;
  div {
    svg {
      height: 24px;
      min-width: 24px;
      transform: ${({ $expanded }) => ($expanded ? 'rotate(-180deg);' : 'rotate(0deg);')};
      transition: all 300ms ease-in-out;
      path {
        stroke: #bdbdbd;
      }
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/10;
  }
`;

const AnswerText = styled(motion.section)`
  background: ${({ $theme }) =>
    $theme.className === 'contrast'
      ? $theme.bgLight
      : hexToRGB({ color: defaultColors.tertiary, alpha: 0.15 })};
  color: ${({ $theme }) => $theme.textColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 24px;
  overflow: hidden;
  ${breakpoints.lg} {
    grid-column: 2/10;
  }
`;

export default Faq;
