import React, { useContext } from 'react';
import styled from 'styled-components';
import { P, breakpoints } from 'styles';
import { LocalContext } from 'context';

const Terms = () => {
  const { theme } = useContext(LocalContext);
  return (
    <SectionWrapper $theme={theme}>
      <P>
        The IPHA Forum events are hosted by Agency X on behalf of the Irish Pharmaceutical
        Healthcare Association (IPHA). Your name, email address, organisation and profession are
        required for you to register and join the event. Once you have completed the registration
        form, the IPHA will provide you with the event link via email so you can access the online
        event. Please see the{' '}
        <a href="https://www.agencyx.ie/privacy-policy/" target="_blank" rel="noreferrer">
          Agency X Privacy Notice
        </a>{' '}
        for more details on how Agency X manages your personal data. Your registration details are
        collected by Agency X and the IPHA. This data will not be used for additional purposes
        without your consent. Please see the{' '}
        <a
          href="https://www.ipha.ie/footer-links/privacy-statement/"
          target="_blank"
          rel="noreferrer">
          IPHA Privacy Notice
        </a>{' '}
        for more details on how IPHA manages personal data. By accepting this invitation from IPHA
        for attendance at this Virtual Event, you agree to the terms and conditions - click{' '}
        <a
          href="https://www.ipha.ie/footer-links/terms-and-conditions/"
          target="_blank"
          rel="noreferrer">
          here
        </a>{' '}
        for more details.
      </P>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: ${({ $theme }) => $theme.muted};
  padding: 3rem 1.75rem;
  width: 100%;
  p {
    color: ${({ $theme }) => $theme.contrastTextColor};
    line-height: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    a {
      text-decoration: underline;
      text-decoration-thickness: from-font;
      text-underline-position: under;
      color: ${({ $theme }) => $theme.contrastTextColor};
    }
  }
  ${breakpoints.lg} {
    padding: 3rem 8rem;
  }
`;

export default Terms;
