import React, { createContext, useState, useEffect, useRef } from 'react';
import { useWindowSize, useLocalStorage } from 'hooks';

const LocalContext = createContext();

const themes = {
  default: {
    className: 'default',
    fg: '#A58A4B',
    bg: 'rgba(196, 196, 196, 0.2)',
    muted: '#025F9F',
    bgLight: '#F3F3F3',
    textColor: '#1A2E59',
    textMuted: '#025F9F',
    contrastTextColor: 'white'
  },
  contrast: {
    className: 'contrast',
    fg: 'yellow',
    bg: 'black',
    muted: '#ECECEC',
    bgLight: 'black',
    textColor: 'yellow',
    textMuted: 'yellow',
    contrastTextColor: 'yellow'
  },
  grayscale: {
    className: 'grayscale',
    fg: '#808080',
    bg: '#282828',
    muted: '#787878',
    bgLight: '#F2F2F2',
    textColor: '#282828',
    contrastTextColor: 'white'
  }
};

const fontSizes = {
  default: '16px',
  medium: '18px',
  large: '20px'
};

const LocalContextProvider = (props) => {
  const [theme, setTheme] = useLocalStorage('ipha-forum-theme', themes.default);
  const [fontSizing, setFontSizing] = useLocalStorage('ipha-forum-fontSize', fontSizes.default);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedPollDocumentation, setSelectedPollDocumentation] = useState('general');
  const [aNewUserHasRegisteredForAnEvent, setANewUserHasRegisteredForAnEvent] = useState(false);
  const [showUserAccountDeletedModal, setShowUserAccountDeletedModal] = useState(false);
  const [minimisedQuestions, setMinimisedQuestions] = useState([]);
  const [showCookieBarModal, setShowCookieBarModal] = useState(false);
  const [registeredRecentlyWithOneStep, setRegisteredRecentlyWithOneStep] = useState(false);
  const [browserTabIsActive, setBrowserTabIsActive] = useState(true);
  const [navOpen, setNavOpen] = useState(false);
  const { windowWidth } = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);
  const registerRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);
  const faqRef = useRef(null);
  const streamRef = useRef(null);
  const headerRef = useRef(null);
  const speakersRef = useRef(null);

  const scrollToRef = (ref) => {
    console.log(ref);
    return ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  useEffect(() => {
    if (windowWidth >= 1150) {
      setIsMobile(false);
    } else setIsMobile(true);
  }, [isMobile, windowWidth]);

  const handleBrowserTabActivity = (forcedFlag) => {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setBrowserTabIsActive(true) : setBrowserTabIsActive(false);
    }
    return document.hidden ? setBrowserTabIsActive(false) : setBrowserTabIsActive(true);
  };

  useEffect(() => {
    const handleBrowserTabActivityFalse = () => handleBrowserTabActivity(false);
    const handleBrowserTabActivityTrue = () => handleBrowserTabActivity(true);

    document.addEventListener('visibilitychange', handleBrowserTabActivity);
    document.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('focus', handleBrowserTabActivityTrue);
    document.addEventListener('focus', handleBrowserTabActivityTrue);

    return () => {
      window.removeEventListener('blur', handleBrowserTabActivity);
      document.removeEventListener('blur', handleBrowserTabActivityFalse);
      window.removeEventListener('focus', handleBrowserTabActivityFalse);
      document.removeEventListener('focus', handleBrowserTabActivityTrue);
      document.removeEventListener('visibilitychange', handleBrowserTabActivityTrue);
    };
  }, []);

  const toggleTheme = (themeName) => {
    if (themeName === 'contrast') setTheme(themes.contrast);
    else if (themeName === 'grayscale') setTheme(themes.grayscale);
    else setTheme(themes.default);
  };

  const toggleFontSizing = (fontSize) => {
    if (fontSize === fontSizes.large) setFontSizing(fontSizes.large);
    else if (fontSize === fontSizes.medium) setFontSizing(fontSizes.medium);
    else setFontSizing(fontSizes.default);
  };

  return (
    <LocalContext.Provider
      value={{
        theme,
        toggle: toggleTheme,
        isMobile,
        fontSizing,
        toggleFont: toggleFontSizing,
        galleryRef,
        registerRef,
        headerRef,
        speakersRef,
        contactRef,
        faqRef,
        scrollTo: scrollToRef,
        streamRef,
        selectedEvent,
        setSelectedEvent,
        aNewUserHasRegisteredForAnEvent,
        setANewUserHasRegisteredForAnEvent,
        showUserAccountDeletedModal,
        setShowUserAccountDeletedModal,
        showCookieBarModal,
        setShowCookieBarModal,
        browserTabIsActive,
        selectedPollDocumentation,
        setSelectedPollDocumentation,
        navOpen,
        setNavOpen,
        registeredRecentlyWithOneStep,
        setRegisteredRecentlyWithOneStep,
        minimisedQuestions,
        setMinimisedQuestions
      }}>
      {props.children}
    </LocalContext.Provider>
  );
};

export { LocalContextProvider };
export default LocalContext;
