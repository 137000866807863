import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { RegistrationForm, Button, LoadingSpinner, Modal } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { motion } from 'framer-motion';
import ICalendarLink from 'react-icalendar-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useWindowSize } from 'hooks';
import { ForumLogo, Add } from 'assets/svgs';
import { H2, H3, P, breakpoints } from 'styles';

const EventHeader = ({ event }) => {
  const eventDetails = {
    title: `${event.title}`,
    description: `Visit https://www.iphaforum.ie/events/${event.slug} to start watching the event.`,
    location: 'Virtual Conference',
    startTime: `${event.start[0]}`,
    endTime: `${event.end[0]}`
  };
  const bannerImage = getImage(event.banner);
  const staticImage = getImage(event.staticBannerImage);
  const { theme, headerRef, isMobile, setNavOpen, setRegisteredRecentlyWithOneStep } =
    useContext(LocalContext);
  const { loading, user, firebase } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;
  const [isRegistering, setIsRegistering] = useState(false);
  const { windowWidth } = useWindowSize();
  const showComponent = !loading && firebase && !user;

  const colors = {
    primary: '#1A2E59',
    secondary: '#025F9F',
    tertiary: '#A58A4B'
  };

  useEffect(() => {
    const video = document.getElementById('bgvid');
    const slowVideo = () => {
      video.playbackRate = 0.75;
    };
    slowVideo();
  }, []);

  const handleRegisterForEventIfUserAlreadyHasAnAccount = async () => {
    setIsRegistering(true);
    const { name, slug, start, end, location } = event;
    const referringUrl =
      (typeof window !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('referringUrlIPHAForumS3E4'))?.data) ||
      'Direct Navigation';

    firebase.registration
      .registerForEventIfAccountAlreadyExists({
        eid: event.eid,
        uid: user.uid,
        referringUrl
      })
      .then(({ data: _user }) => {
        const { eventsUserCanAccess } = _user;
        setIsRegistering(false);
        setRegisteredRecentlyWithOneStep(true);
        window.localStorage.removeItem('referringUrlIPHAForumS3E4');
      })
      .catch((error) => {
        console.error(error);
        setIsRegistering(false);
      });
  };

  return (
    <Header
      ref={headerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <Banner>
        {event.bannerVideo ? (
          <>
            <BannerVideo playsInline autoPlay muted loop id="bgvid">
              <source src={event.bannerVideo} type="video/mp4" />
            </BannerVideo>
            <StaticBannerImage image={staticImage} event={event.eid} alt="Hub Placeholder" />
          </>
        ) : (
          <BannerImage image={bannerImage} alt="Hub Placeholder" />
        )}
        <Blend />
      </Banner>
      <Container event={event.eid}>
        <TextBox $theme={theme}>
          <LogoContainer>
            <ForumLogo />
          </LogoContainer>
          <SlideCaptionDate
            $theme={theme}
            dangerouslySetInnerHTML={{
              __html: event.date
            }}
          />
          <SlideTitle
            $eid={event.eid}
            dangerouslySetInnerHTML={{
              __html: event.name
            }}
          />
          <>
            <SlideCaptionSubtitle>{event.subtitle}</SlideCaptionSubtitle>
            <SlideCaptionText
              $theme={theme}
              dangerouslySetInnerHTML={{
                __html: event.description
              }}
            />
          </>
          {!loading &&
            firebase &&
            user &&
            !user.eventsUserWantsToAccess.includes(event.eid) &&
            !user.eventsUserHasBeenDeniedAccessTo.includes(event.eid) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}>
                <Button
                  shine
                  onClick={() => handleRegisterForEventIfUserAlreadyHasAnAccount()}
                  whileHover={{ scale: 1.025 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    backgroundColor: colors.fg,
                    width: '250px',
                    height: '3.5rem',
                    marginBottom: '1.75rem',
                    lineHeight: '1.2rem',
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  {isRegistering ? (
                    <LoadingSpinner style={{ color: '#fff', width: '2rem' }} />
                  ) : (
                    'One-Step Registration'
                  )}
                </Button>
              </motion.div>
            )}
          {!currentUser && isMobile && (
            <Button
              type="button"
              width={event?.status === 'past' ? '350px' : '150px'}
              style={{ marginBottom: '1.75rem' }}
              onClick={() => setNavOpen(true)}>
              Register Here
            </Button>
          )}
          {!currentUser && event?.status === 'future' && (
            <StyledDateButton
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 0.95 }}
              width="250px"
              theme={theme}>
              <AddIcon theme={theme} />
              <ICalendarLink
                style={{
                  height: '3rem',
                  background: 'transparent',
                  margin: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '-30px',
                  position: 'relative'
                }}
                event={eventDetails}>
                Add Date To Calendar
              </ICalendarLink>
            </StyledDateButton>
          )}
        </TextBox>
        {windowWidth >= 1150 && showComponent && (
          <FormContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            <RegistrationForm colors={colors} contrast event={event} />
          </FormContainer>
        )}
      </Container>
    </Header>
  );
};

const LogoContainer = styled.div`
  height: auto;
  margin: 3rem 0;
  width: 100%;
  ${breakpoints.sm} {
    margin: 2rem 0;
    width: 325px;
  }
  ${breakpoints.lg} {
    margin: 2rem 0;
    width: 425px;
  }
`;

const FormContainer = styled(motion.div)`
  align-self: end;
  height: 100%;
  padding: 0.625rem;
  padding-top: 5rem;
  position: relative;
  z-index: 2;
`;

const TextBox = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2em 1em 1em 1rem;
  position: relative;
  width: 100%;
  z-index: 3;
  p {
    color: #ffffff;
    font-weight: normal;
  }
  ${breakpoints.sm} {
    padding: 4em 1em 1em 1rem;
  }
  ${breakpoints.lg} {
    align-self: end;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 3;
    p {
      color: #ffffff;
      font-weight: normal;
    }
  }
`;

const SlideTitle = styled(H2)`
  color: white;
  font-size: ${({ $eid }) => ($eid === '6' || $eid === '7' ? '2.75rem' : '3rem')};
  line-height: ${({ $eid }) => ($eid === '6' || $eid === '7' ? '3rem' : '3.25rem')};
  margin-bottom: 0;
  p {
    font-size: 2.375rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
`;

const SlideCaptionDate = styled(H3)`
  color: ${({ $theme }) => $theme.fg};
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5em;
  position: relative;
  :first-child {
    margin-bottom: 0.75rem;
  }
  span {
    font-weight: 600;
  }
  ${breakpoints.lg} {
    font-size: 1.5rem;
    margin: 1rem 0 1.25rem 0;
  }
`;

const SlideCaptionSubtitle = styled(H3)`
  color: #ffffff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25em;
  position: relative;
  :first-child {
    margin-bottom: 0.75rem;
  }
  ${breakpoints.lg} {
    font-size: 1.25rem;
    margin: 0.5rem 0 2rem 0;
  }
`;

const SlideCaptionText = styled(P)`
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1.5em;
  ${breakpoints.sm} {
    max-width: 75%;
  }
  ${breakpoints.lg} {
    font-size: 1rem;
    line-height: 1.25rem;
    max-width: 100%;
  }
`;

const Container = styled.div`
  align-items: center;
  grid-column: 1 / 7;
  justify-content: center;
  padding: 0.625rem;
  width: 100%;
  @media (min-width: 1024px) {
    grid-column: 1/ 13;
  }
  ${breakpoints.lg} {
    display: grid;
    gap: ${({ event }) => (event === '6' ? '2rem' : '3rem')};
    grid-column: 2 / 12;
    grid-template-columns: 55% 45%;
    padding-bottom: 3rem;
    padding-top: 6rem;
  }
`;

const StyledDateButton = styled(motion.div)`
  align-items: center;
  background: transparent;
  border-radius: 30px;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  left: -0.5rem;
  padding: 1rem 1rem 1rem 0;
  position: relative;
  width: 280px;
  z-index: 2;
  a {
    align-items: center;
    background: white;
    background-color: transparent;
    color: ${({ theme }) => theme.fg};
    cursor: pointer;
    display: flex;
    font-family: 'Open Sans', tahoma, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    justify-content: flex-start;
    list-style: none;
    margin: 0.5rem 0;
    text-transform: uppercase;
    transition: 0.5s;
    width: ${(props) => (props.width ? props.width : '100%')};
  }
  svg {
    circle {
      fill: ${({ theme }) => theme.fg};
    }
    path {
      stroke: #fff;
    }
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const AddIcon = styled(Add)`
  height: 3rem;
  margin-right: 0.5rem;
  width: 1.5rem;
`;

const BannerImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
`;

const StaticBannerImage = styled(GatsbyImage)`
  height: 100%;
  position: absolute;
  right: 0px !important;
  top: 4rem;
  width: 100%;
  z-index: 2;
  img {
    object-position: ${({ event }) => (event === '9' ? 'right top' : 'right bottom')};
  }
`;

const Blend = styled.div`
  background: linear-gradient(90deg, rgba(26, 46, 89, 1) 25%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
  position: absolute;
  right: 0;
  top: 50px;
  width: 100%;
  z-index: 3;
`;

const BannerVideo = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Banner = styled.div`
  grid-column: 1/7;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  @media (min-width: 1024px) {
    grid-column: 1/13;
  }
`;

const Header = styled.header`
  background: white;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  position: relative;
  width: 100%;
  @media (min-width: 1024px) {
    min-height: 100%;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const CallToAction = styled(P)`
  margin-top: 3rem;
  text-align: right;
  button {
    background: transparent;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
    text-decoration: underline;
    text-underline-position: under;
  }
`;

export default EventHeader;
