import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import BaseSelect from 'react-select';
import { FirebaseContext } from 'context';
import { LoadingSpinner } from 'components';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

const roles = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Moderator', value: 'Moderator' }
];

function RoleManagement({ events, customDropdownMenuStyles }) {
  const { firebase } = useContext(FirebaseContext);
  const [uid, setUid] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [formIncomplete, setFormIncomplete] = useState(true);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [submitting, setSubmitting] = useState(false);
  /* const [addingFields, setAddingFields] = useState(false); */

  const clearFields = () => {
    setUid('');
    setSelectedUser(null);
    setSelectedEvent(null);
    setSelectedRole(null);
    setFeedback('');
  };

  /* const handleAddFields = async () => {
    setAddingFields(true);
    try {
      await firebase.admin.addNewFieldsToAllUserDocs();
    } catch (error) {
      console.error(error);
    } finally {
      setAddingFields(false);
    }
  }; */

  const handleSubmit = async () => {
    if (selectedRole.value === 'Moderator') {
      if (uid && selectedEvent.eid) {
        setSubmitting(true);
        try {
          if (alreadyExists) {
            await firebase.admin.removeModeratorRole({
              uid,
              eid: selectedEvent.eid
            });
          } else {
            await firebase.admin.grantModeratorRole({
              uid,
              eid: selectedEvent.eid
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          setSubmitting(false);
          clearFields();
        }
      } else {
        setFeedback('All fields are required');
      }
    } else if (selectedRole.value === 'Admin') {
      if (uid) {
        setSubmitting(true);
        try {
          await firebase.admin.grantAdminRole({
            uid,
            eid: selectedEvent.eid
          });
        } catch (error) {
          console.error(error);
        } finally {
          setSubmitting(false);
          clearFields();
        }
      } else {
        setFeedback('You need to provide a uid');
      }
    }
  };

  useEffect(() => {
    if (uid.length > 26) {
      firebase.admin
        .getUserFromDatabaseWithUID({ uid })
        .then((res) => {
          if (!res.empty) {
            setSelectedUser(res.docs[0].data());
            setFeedback('');
          } else {
            setSelectedUser(null);
            setFeedback("Can't find user, please check if uid value is correct");
          }
        })
        .catch((error) => {
          console.error(error);
          setSelectedUser(null);
          setFeedback("Can't find user, please check if uid value is correct");
        });
    }
    if (uid.length === 0) {
      setFeedback('');
    }
  }, [uid]);

  useEffect(() => {
    if (selectedUser && selectedRole) {
      if (selectedRole.label === 'Admin') {
        setFormIncomplete(false);
        if (selectedUser.roles.isAdmin) {
          setFeedback('User already admin');
          setFormIncomplete(true);
        }
      } else {
        setFormIncomplete(true);
      }
    } else {
      setFormIncomplete(true);
    }
    if (selectedUser && selectedEvent && selectedRole) {
      setFormIncomplete(false);
      if (selectedRole.label === 'Moderator') {
        setAlreadyExists(selectedUser.roles.isModerator.includes(selectedEvent.eid));
      } else {
        setAlreadyExists(false);
      }
    }
  }, [selectedUser, selectedEvent, selectedRole]);

  return (
    <Container>
      <Title>Role Management</Title>
      <Card>
        {feedback && <Feedback>{feedback}</Feedback>}
        <Input
          type="text"
          id="uid"
          name="uid"
          placeholder="UID"
          value={uid}
          onChange={(e) => setUid(e.target.value)}
        />
        {selectedRole?.label !== 'Admin' && (
          <div>
            <Label>Event</Label>
            <FormSelect
              value={events.filter(
                ({ value }) =>
                  value ===
                  `Event ${selectedEvent?.eid} - ${selectedEvent?.name.replace('<br />', ' ')}`
              )}
              onChange={setSelectedEvent}
              placeholder="Select"
              styles={customDropdownMenuStyles()}
              options={events}
            />
          </div>
        )}
        <div>
          <Label>Role</Label>
          <FormSelect
            value={selectedRole}
            onChange={setSelectedRole}
            placeholder="Select"
            styles={customDropdownMenuStyles()}
            options={roles}
          />
        </div>
        <AnimatePresence>
          {selectedRole && (
            <AnimatePresence>
              {alreadyExists && selectedRole.label !== 'Admin' ? (
                <RemoveButton
                  type="button"
                  disabled={formIncomplete}
                  onClick={() => handleSubmit()}
                  whileHover={!formIncomplete && { scale: 1.0125 }}
                  whileTap={
                    !formIncomplete && {
                      scale: 0.95
                    }
                  }>
                  {submitting ? (
                    <LoadingSpinner style={{ width: '1.25rem', color: '#fff' }} />
                  ) : (
                    <span>Remove Permission</span>
                  )}
                </RemoveButton>
              ) : (
                <AddButton
                  type="button"
                  disabled={formIncomplete || (alreadyExists && selectedRole.label !== 'Admin')}
                  onClick={() => handleSubmit()}
                  whileHover={!formIncomplete && { scale: 1.0125 }}
                  whileTap={
                    !formIncomplete && {
                      scale: 0.95
                    }
                  }>
                  {submitting ? (
                    <LoadingSpinner style={{ width: '1.25rem', color: '#fff' }} />
                  ) : selectedRole.label === 'Admin' ? (
                    <span>Make User A Site Admin</span>
                  ) : (
                    <span>{`Make User An Event ${selectedRole.value}`}</span>
                  )}
                </AddButton>
              )}
            </AnimatePresence>
          )}
          {/* Button to add new fields, field must be changed in 
          functions/groups/user/account/add-new-fields-to-all-user-docs.js  */}
          {/*  <AddFields type="button" onClick={() => handleAddFields()}>
            {addingFields? (
              <LoadingSpinner style={{ width: '1.25rem', color: '#fff' }} />
            ) : (
              <span>Add New Fields</span>
            )}
          </AddFields> */}
        </AnimatePresence>
      </Card>
    </Container>
  );
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Title = styled.h3`
  color: #004d9a;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;
  width: 100%;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  font-weight: 300;
  gap: 1.75rem;
  max-width: 575px;
  padding: 3.875rem 1.75rem 2.75rem 1.75rem;
  position: relative;
  width: 100%;
`;

const Feedback = styled.span`
  color: #f55151;
  font-size: 0.875rem;
  left: 1.75rem;
  position: absolute;
  top: 2.125rem;
`;

const Input = styled(motion.input)`
  border: 1px solid #c4c4c4;
  color: #004d9a;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 40px;
  outline: none;
  padding: 0 1.75rem;
  :focus {
    border: 1.5px solid #c4c4c4;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #004d9a;
    font-style: italic;
    font-weight: 300;
    opacity: 0.75;
  }
`;

const Label = styled.label`
  align-items: center;
  color: #004d9a;
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.125rem;
`;

const AddButton = styled(motion.button)`
  align-items: center;
  background-color: ${({ disabled }) => (disabled ? '#c4c4c4' : '#004d9a')};
  border-radius: 3rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  min-width: 240px;
  padding: 1.125rem 2rem;
  text-transform: uppercase;
  transition: background-color 200ms ease-in-out;
  width: max-content;
  :disabled {
    cursor: default;
  }
`;

const RemoveButton = styled(AddButton)`
  background-color: ${({ disabled }) => (disabled ? '#c4c4c4' : '#F55151')};
  margin-left: auto;
`;

const AddFields = styled(AddButton)``;

export default RoleManagement;
