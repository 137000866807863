import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { H5, P, breakpoints } from 'styles';
import { Markup } from 'interweave';
import { useWindowSize } from 'hooks';
import { hexToRGB } from 'utils';

const Agendas = ({ agendas, colors }) => {
  const { agendaRef, theme } = useContext(LocalContext);
  const { windowWidth } = useWindowSize();
  const [selected, setSelected] = useState(agendas[0]);
  const { slots } = selected;

  return (
    <AgendaList>
      <div ref={agendaRef} style={{ position: 'absolute', top: '-10rem' }} />
      <Container>
        <GridTitle $colors={colors} $theme={theme}>
          Agendas
        </GridTitle>
        <Days>
          {agendas.map((agenda) => (
            <Selector
              key={agenda.day}
              $colors={colors}
              $theme={theme}
              $active={selected.day === agenda.day}
              onClick={() => {
                setSelected(agenda);
              }}>
              Day {agenda.day}
            </Selector>
          ))}
        </Days>

        <Agenda $colors={colors} $theme={theme}>
          <Title>{selected.title}</Title>
          {windowWidth >= 768 && (
            <Grid className="inactive">
              <Time>Time:</Time>
              <Name style={{ fontSize: '1rem', fontWeight: 'normal' }}>Livestream</Name>

              <Speaker style={{ fontSize: '1rem', fontWeight: 'normal' }}>Keynote Speaker</Speaker>
            </Grid>
          )}

          {slots.map((slot) => (
            <Grid key={slot.orderNumber} $colors={colors}>
              <Time>
                {windowWidth < 768 && <span>Time:</span>}
                {slot.timeSlot}
              </Time>
              <Name>
                <Markup content={slot.title} />
              </Name>
              <Speaker>
                <Markup content={slot.speaker} />
              </Speaker>
            </Grid>
          ))}
        </Agenda>
      </Container>
    </AgendaList>
  );
};

const GridTitle = styled(H5)`
  color: ${({ $theme, $colors }) =>
    $theme.className === 'contrast' ? 'yellow' : $colors.secondary};
  grid-column: 2/6;

  ${breakpoints.sm} {
    grid-column: 2/12;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const Name = styled(P)`
  grid-column: 1/7;
  strong,
  ul {
    font-weight: 600;
  }
  ul {
    margin-left: 2rem;
  }
  ${breakpoints.sm} {
    grid-column: 3/7;
  }
  ${breakpoints.lg} {
    grid-column: 2/5;
  }
`;

const Speaker = styled(P)`
  grid-column: 1/7;
  ${breakpoints.sm} {
    grid-column: 8/11;
  }
  ${breakpoints.lg} {
    grid-column: 9/11;
  }
`;

const Time = styled(P)`
  grid-column: 1/2;
  span {
    margin-right: 0.375rem;
  }
  ${breakpoints.sm} {
    grid-column: 1/3;
  }
  ${breakpoints.lg} {
    grid-column: 1/2;
  }
`;

const Grid = styled.div`
  color: ${({ className, $colors }) => (className === 'inactive' ? 'initial' : $colors.secondary)};
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 1.75rem;
  padding: 0.5rem 1.5rem;
  transition: 0.25s;
  &:hover {
    background: ${({ className, $colors }) =>
      className === 'inactive' ? 'initial' : hexToRGB({ color: $colors.primary, alpha: 0.25 })};
  }
  ${breakpoints.sm} {
    margin-bottom: 0.75rem;
    grid-template-columns: repeat(10, 1fr);
    grid-column: 2/12;
    grid-gap: 2rem;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const Title = styled(H5)`
  color: #00004e;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3em;
  margin-bottom: 2rem;
`;

const Agenda = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  ${Title} {
    color: ${({ $theme, $colors }) =>
      $theme.className === 'contrast' ? 'yellow' : $colors.secondary};
  }
  ${Name} {
    color: ${({ $theme, $colors }) =>
      $theme.className === 'contrast' ? 'yellow' : $colors.secondary};
  }
  ${Time} {
    color: ${({ $theme, $colors }) =>
      $theme.className === 'contrast' ? 'yellow' : $colors.secondary};
  }
  ${Speaker} {
    color: ${({ $theme, $colors }) =>
      $theme.className === 'contrast' ? 'yellow' : $colors.secondary};
  }
`;

const Selector = styled.button`
  background: ${({ $active, $colors, $theme }) =>
    $active && $theme.className === 'contrast'
      ? 'yellow'
      : $active && $theme.className !== 'contrast'
      ? $colors.primary
      : 'transparent'};
  border: 1px solid;
  border-color: ${({ $active, $colors, $theme }) =>
    $active && $theme.className === 'contrast'
      ? 'black'
      : $active && $theme.className !== 'contrast'
      ? 'white'
      : !$active && $theme.className === 'contrast'
      ? 'yellow'
      : $colors.tertiary};
  border-radius: 2rem;
  color: ${({ $active, $colors, $theme }) =>
    $active && $theme.className === 'contrast'
      ? 'black'
      : $active && $theme.className !== 'contrast'
      ? 'white'
      : !$active && $theme.className === 'contrast'
      ? 'yellow'
      : $colors.tertiary};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  height: 3rem;
  text-align: center;
  transition: 0.25s;
`;

const Days = styled.div`
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: 7.5rem 7.5rem;
  margin: 2rem 0;
  width: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: auto;
  justify-content: flex-start;
  width: 100%;
  ${breakpoints.lg} {
    grid-column: 2/12;
  }
`;

const AgendaList = styled.div`
  display: grid;
  grid-column: 1/13;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 3rem auto;
  max-width: 1440px;
  padding: 1rem;
  position: relative;
  width: 100%;
  ${breakpoints.lg} {
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
  }
`;

export default Agendas;
