import React, { useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { LoadingSpinner, Modal, LoginModal } from 'components';
import { FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { defaultColors } from 'styles';

const ProtectedRoute = ({
  children,
  eid,
  eventSlug,
  eventIsHidden,
  eventStatus,
  isAdmin,
  isModerator
}) => {
  const { firebase, loading, user } = useContext(FirebaseContext);
  const firebaseHasLoaded = !loading && firebase;
  const userIsInFirebaseAuth = firebase?.auth?.currentUser;
  const { pathname } = useLocation();

  // We allow an event livestream page to be accessed when 'eventIsHidden' so that IPHA can
  // preview/approve the livestream page of a hidden event.
  const openGatedContent =
    eventIsHidden ||
    eventStatus === 'past' ||
    (eid && user && user.eventsUserCanAccess.includes(eid)) ||
    isAdmin ||
    isModerator;
  return (
    <>
      <AnimatePresence>
        {loading && (
          <LoadingSpinnerWrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <LoadingSpinner style={{ width: '8rem' }} />
          </LoadingSpinnerWrapper>
        )}
      </AnimatePresence>
      {(openGatedContent || (firebaseHasLoaded && userIsInFirebaseAuth && user)) &&
        (openGatedContent ? children : navigate('/'))}
      {!openGatedContent && firebaseHasLoaded && !userIsInFirebaseAuth && (
        <Modal
          // disableCloseOnClickOutside
          hideCloseButton
          openWithoutTrigger
          redirectIfNotAuthorised
          modalContent={<LoginModal eventSlug={eventSlug} colors={defaultColors} />}
        />
      )}
    </>
  );
};

const LoadingSpinnerWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
`;

export default ProtectedRoute;
