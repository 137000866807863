import React, { useContext, useState, useEffect } from 'react';
import { Button, ErrorMessage, LoadingSpinner, Modal, LoginModal } from 'components';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import Autocomplete from 'react-google-autocomplete';
import BaseSelect from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import { hexToRGB, capitaliseFirstLetterOfText, checkIfNameIncludesOnlyStandardChars } from 'utils';
import { fadeInAndOutVariants, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import {
  FormContainer,
  FormInput,
  FormInputLabel,
  FormChecks,
  FormCheckbox,
  FormTerms
} from '../FormComponents';
import FixRequiredSelect from '../FixRequiredSelect';

const FormSelect = (props) => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />
);

const resetForm = () => ({
  name: '',
  email: '',
  company: '',
  termsAndConditions: false,
  referringUrl:
    typeof window !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('referringUrlIPHAForumS3E4'))?.data
});

const customDropdownMenuStyles = () => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    background: 'transparent',
    border: state.isFocused ? '0.188em solid #1A2E59' : '0.063em solid #1A2E59',
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '1rem',
    fontWeight: 'bold',
    height: '3.75rem',
    letterSpacing: '0.031rem',
    padding: '0'
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    padding: '0 0 0 1.25rem',
    cursor: 'pointer',
    height: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#1A2E59',
    '&:hover': {
      color: '#1A2E59'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#1A2E59'
  }),
  menu: (provided) => ({
    ...provided,
    background: '#1A2E59',
    border: '0.214em solid #1A2E59',
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.214em',
    letterSpacing: '0.071em',
    margin: '0',
    width: 'calc(100% + 0.429em)'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#1A2E59'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? hexToRGB({ color: '#A58A4B', alpha: 1 }) : 'transparent',
    paddingLeft: '1.25rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#007FC0'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'Noto Sans', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#1A2E59'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#1A2E59'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0'
  })
});

const includesSurname = (name) => /\s/.test(name.trim());

const RegistrationForm = ({ event, colors }) => {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const { setANewUserHasRegisteredForAnEvent } = useContext(LocalContext);
  const [formValues, setFormValues] = useState(resetForm());
  const [errorMessage, setErrorMessage] = useState('');
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [disableSubmitButtonWhileCheckingEmailAddress, setDisableSubmitButtonWhileCheckingInputs] =
    useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 750;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => {
    const { name } = formValues;
    if (name) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(() => {
        if (!checkIfNameIncludesOnlyStandardChars(name)) {
          setErrorMessage('Please include only standard characters in your name');
        } else if (!includesSurname(name)) {
          setErrorMessage('Please include a surname');
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.name]);

  useEffect(() => {
    const { email } = formValues;
    if (email) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(async () => {
        try {
          const { data: _userAlreadyExists } =
            await firebase.registration.checkIfAccountAlreadyExists({ email });
          if (_userAlreadyExists) {
            setUserAlreadyExists(true);
            throw new Error(
              "This email address already belongs to a IPHA Forum account. Click 'Login' above if it belongs to you."
            );
          } else if (userAlreadyExists) {
            setUserAlreadyExists(false);
            setErrorMessage('');
          }
        } catch (error) {
          setErrorMessage(error.message);
        } finally {
          setDisableSubmitButtonWhileCheckingInputs(false);
        }
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    if (errorMessage && !userAlreadyExists && name !== 'email') {
      setErrorMessage('');
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleWorkplaceAutocompleteSelection = (res) => {
    const { name } = res;
    if (errorMessage && !userAlreadyExists) {
      setErrorMessage('');
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      company: name.split(',')[0]
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (errorMessage || submissionStatus.submitting) {
      return;
    }

    const { name, email, company, termsAndConditions } = formValues;

    if (!name) {
      setErrorMessage('Please include your name');
      return;
    }

    if (!includesSurname(name)) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!email) {
      setErrorMessage('Please include an email address');
      return;
    }

    if (userAlreadyExists) {
      setErrorMessage(
        "This email address already belongs to a IPHA Forum account. Click 'Login' above if it belongs to you."
      );
      return;
    }

    if (!company) {
      setErrorMessage('Please include your organisation');
      return;
    }

    if (!termsAndConditions) {
      setErrorMessage('You must agree to our Terms & Conditions to register');
      return;
    }

    try {
      setSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const actionCodeSettings = {
        url: `${window.location.origin}?eventSlug=${event?.slug || ''}`,
        handleCodeInApp: true
      };

      const firstName = name.split(' ')[0];

      const { data } = await firebase.emails.sendSignInWithMagicLinkEmail({
        firstName: capitaliseFirstLetterOfText(firstName),
        email,
        eventName: event ? event.title : 'IPHA Forum',
        actionCodeSettings
      });

      if (data.Message === 'OK') {
        setSubmissionStatus({
          submitting: false,
          submitted: true
        });
        window.localStorage.setItem(
          'newUserIPHAForum',
          JSON.stringify({
            ...formValues,
            eventName: event?.title || '',
            eid: event?.eid || '',
            eventSlug: event?.slug || '',
            actionCodeSettings
          })
        );
        setANewUserHasRegisteredForAnEvent(true);
      } else {
        setSubmissionStatus({
          submitting: false,
          submitted: false
        });
        setErrorMessage('Error sending email. Please try again');
      }

      setFormValues(resetForm());
    } catch (error) {
      console.error(error);

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  return (
    <StyledFormContainer onSubmit={(e) => handleSubmit(e)} $theme={theme}>
      <div
        style={{
          color: `${theme.textColor}`,
          fontSize: '1.75rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
          marginBottom: '3rem',
          textAlign: 'center'
        }}>
        {event?.status === 'past'
          ? 'Register below to watch the recorded livestream'
          : 'Register Here'}
      </div>
      <div
        style={{
          color: `${theme.textColor}`,
          display: 'flex',
          fontSize: '0.875rem',
          justifyContent: 'center',
          marginBottom: '2.625em'
        }}>
        Already have an IPHA Forum account?&nbsp;
        <Modal
          trigger={
            <span
              style={{
                fontSize: '0.875rem',
                textAlign: 'center',
                marginBottom: '1.5em',
                textDecoration: 'underline'
              }}>
              Click here to login.
            </span>
          }
          disableCloseOnClickOutside
          modalContent={<LoginModal colors={colors} />}
        />
      </div>
      <div
        style={{
          position: 'relative',
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '1fr'
        }}>
        <motion.div
          style={{
            gridRow: 1,
            gridColumn: 1
          }}
          variants={fadeInAndOutVariants()}
          initial="initial"
          animate="animate"
          exit="exit">
          <Fields>
            <FormInput
              theme={theme}
              id="name"
              name="name"
              onChange={handleInputChange}
              placeholder="Full Name*"
              type="text"
              value={formValues.name}
              required
            />
            <FormInput
              theme={theme}
              id="email"
              name="email"
              onChange={handleInputChange}
              placeholder="Email*"
              type="email"
              value={formValues.email}
              required
            />
            <FormWorkplaceAutoComplete
              id="company"
              name="company"
              apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
              options={{
                types: ['establishment'],
                componentRestrictions: { country: 'ie' },
                fields: ['name']
              }}
              onChange={handleInputChange}
              onPlaceSelected={handleWorkplaceAutocompleteSelection}
              placeholder="Organisation*"
              type="select"
              required
              value={formValues.company}
            />
          </Fields>
          <FormChecks>
            <FormInputLabel hide htmlFor="termsAndConditions">
              Terms And Conditions
            </FormInputLabel>
            <FormCheckbox
              $theme={theme}
              checked={formValues.termsAndConditions}
              id="termsAndConditions"
              name="termsAndConditions"
              onChange={handleInputChange}
              required
              type="checkbox"
            />
            <FormTerms theme={theme}>
              {event?.termsAndConditions || (
                <p>
                  By checking this box, I agree to{' '}
                  <a
                    href="https://www.ipha.ie/footer-links/terms-and-conditions/"
                    target="_blank"
                    rel="noreferrer">
                    IPHA&apos;s terms and conditions
                  </a>
                  .*
                </p>
              )}
            </FormTerms>
          </FormChecks>
          <ErrorMessage
            errorMessage={errorMessage}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1.25rem'
            }}>
            {submissionStatus.submitted ? (
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '0.5rem',
                  color: theme.fg || '#1A2E59',
                  width: '90%'
                }}>
                Check your inbox to verify your email address and complete your registration.
                <strong>Can&apos;t find our email? Be sure to check your junk!</strong>
              </div>
            ) : (
              <Button
                type="submit"
                width="7.5rem"
                whileTap={{ scale: 0.95 }}
                disabled={userAlreadyExists || disableSubmitButtonWhileCheckingEmailAddress}
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  opacity: 1
                }}>
                {
                  <AnimatePresence>
                    {submissionStatus.submitting && (
                      <LoadingSpinner
                        style={{ width: '2rem', color: 'white', position: 'absolute' }}
                      />
                    )}
                  </AnimatePresence>
                }
                {
                  <AnimatePresence>
                    {!submissionStatus.submitting && (
                      <motion.span
                        variants={fadeInAndOutVariants()}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        style={{ color: 'white' }}>
                        Submit
                      </motion.span>
                    )}
                  </AnimatePresence>
                }
              </Button>
            )}
          </div>
        </motion.div>
      </div>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: #ededed;

  @media screen and (min-width: 1150px) {
    background: #ededed;
  }

  input {
    margin-bottom: 0;
    color: ${({ theme }) => theme.textColor};
    border: 1px solid ${({ theme }) => theme.textColor};
    :focus {
      border: 0.188em solid ${({ theme }) => theme.textColor};
    }
  }

  label {
    color: ${({ theme }) => theme.textColor};
  }

  input:focus {
    border: 0.188em solid ${({ theme }) => theme.textColor};
  }

  select {
    border: 0.188em solid ${({ theme }) => theme.textColor};
    color: ${({ theme }) => theme.textColor} !important;
  }

  button {
    background: ${({ theme }) => theme.fg};
  }
`;

const Fields = styled.div`
  display: grid;
  grid-row-gap: 1.625rem;
  grid-template-columns: 1fr;
  margin-bottom: 1.625rem;
`;

const FormWorkplaceAutoComplete = styled(Autocomplete)`
  align-items: center;
  background: transparent;
  border: 0.063rem solid #1a2e59;
  color: #1a2e59 !important;
  display: flex;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  height: 3.75rem;
  letter-spacing: 0.031rem;
  outline: none;
  padding: 0 1.25rem;

  ::placeholder {
    color: #1a2e59 !important;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
  }
`;

export default RegistrationForm;
