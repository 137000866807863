import React, { useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';

const AccessibilityBar = () => {
  const { theme, toggle, fontSizing, toggleFont } = useContext(LocalContext);
  return (
    <ThemeDropdown
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.75 }}
      $theme={theme}>
      <ThemeDropdownTitle>Accessibility</ThemeDropdownTitle>
      <ThemeColorOptions>
        <ThemeColorButton
          $theme={theme}
          active={theme.className === 'default'}
          onClick={() => toggle('default')}>
          Default
        </ThemeColorButton>
        <ThemeColorButton
          $theme={theme}
          active={theme.className === 'contrast'}
          onClick={() => toggle('contrast')}>
          Contrast
        </ThemeColorButton>
        <ThemeColorButton
          $theme={theme}
          active={theme.className === 'grayscale'}
          onClick={() => toggle('grayscale')}>
          Grayscale
        </ThemeColorButton>
      </ThemeColorOptions>
      <ThemeDropdownTitle $theme={theme}>Font Size</ThemeDropdownTitle>
      <ThemeFontOptions>
        <ThemeFontButton
          $theme={theme}
          active={fontSizing === '16px' ? true : null}
          onClick={() => toggleFont('16px')}>
          A
        </ThemeFontButton>
        <ThemeFontButton
          $theme={theme}
          active={fontSizing === '18px' ? true : null}
          onClick={() => toggleFont('18px')}>
          A
        </ThemeFontButton>
        <ThemeFontButton
          $theme={theme}
          active={fontSizing === '20px' ? true : null}
          onClick={() => toggleFont('20px')}>
          A
        </ThemeFontButton>
      </ThemeFontOptions>
    </ThemeDropdown>
  );
};

const ThemeDropdownTitle = styled.h4`
  display: block;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  ${breakpoints.lg} {
    margin-bottom: 0px;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    :nth-child(1) {
      margin-left: 0px;
    }
  }
`;

const ThemeColorOptions = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  width: 100%;
  ${breakpoints.lg} {
    margin-bottom: 0px;
  }
`;

const ThemeColorButton = styled.button`
  background: ${({ active, $theme }) => (active === true ? $theme.fg : 'transparent')};
  border: ${({ active, $theme }) =>
    active === true ? `1px solid ${$theme.fg}` : `1px solid ${$theme.textColor}`};
  border-radius: 3rem;
  color: ${({ active, $theme }) => (active === true ? $theme.bgLight : $theme.textColor)};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  height: 2rem;
  line-height: 1rem;
  max-width: 100%;
  width: 6rem;
  :nth-child(2) {
    margin: 0px 0.625rem;
  }
`;

const ThemeFontOptions = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  width: 100%;
  ${breakpoints.lg} {
    margin-bottom: 0px;
  }
`;

const ThemeFontButton = styled.button`
  background: ${({ active, $theme }) => (active === true ? $theme.fg : 'transparent')};
  border: ${({ active, $theme }) =>
    active === true ? `1px solid ${$theme.fg}` : `1px solid ${$theme.TextColor}`};
  border-radius: 3rem;
  color: ${({ active, $theme }) => (active === true ? $theme.bgLight : $theme.textColor)};
  cursor: pointer;
  font-weight: 600;
  height: 2rem;
  width: 3rem;
  :nth-child(1) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  :nth-child(2) {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0px 1.25rem;
  }
  :nth-child(3) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

const ThemeDropdown = styled(motion.div)`
  background: ${({ $theme }) => $theme.bgLight};
  box-shadow: 0px 0.185rem 0.625rem rgba(0, 0, 0, 0.25);
  left: 0;
  padding: 0.625rem 1.25rem;
  position: fixed;
  top: 3.75rem;
  width: 100%;
  z-index: -2;
  h4 {
    color: ${({ $theme }) => $theme.textColor};
  }
  ${breakpoints.lg} {
    align-items: center;
    display: flex;
    left: initial;
    position: absolute;
    right: 0px;
    top: 4rem;
    width: auto;
  }
`;

export default AccessibilityBar;
