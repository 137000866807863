import React, { useState, useContext, useEffect } from 'react';
import { FirebaseContext } from 'context';
import styled from 'styled-components';
import { EmojiClap, EmojiLike, EmojiLove, EmojiSmile } from 'assets/svgs';
import AnalyticsBar from '../../AnalyticsBar';

function AnalyticsEmojiBar({ eid }) {
  const { firebase } = useContext(FirebaseContext);
  const [emojis, setEmojis] = useState([]);
  const [emojiValues, setEmojiValues] = useState({
    smile: 0,
    thumbsUp: 0,
    heart: 0,
    clap: 0
  });

  useEffect(() => {
    let unsubscribeFromEventEmojis;
    if (firebase) {
      unsubscribeFromEventEmojis = firebase.interaction.emojis.subscribeToEventEmojis({
        eid,
        snapshot: (snapshot) => {
          if (snapshot.exists) {
            const emids = Object.keys(snapshot.val());
            setEmojis(emids.map((emid) => snapshot.val()[emid].emojiType));
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventEmojis) {
        unsubscribeFromEventEmojis();
      }
    };
  }, [firebase]);

  useEffect(() => {
    setEmojiValues({
      smile: emojis.filter((emoji) => emoji === 'smile').length,
      thumbsUp: emojis.filter((emoji) => emoji === 'like').length,
      heart: emojis.filter((emoji) => emoji === 'love').length,
      clap: emojis.filter((emoji) => emoji === 'clap').length
    });
  }, [emojis]);

  return (
    <Container>
      <AnalyticsBar title="Emojis" padding="1.25rem" height="225px">
        <Analytic>
          <EmojiSmile />
          <span>{emojiValues.smile}</span>
          <p>Total number&nbsp;of smiles</p>
        </Analytic>
        <Analytic>
          <EmojiLike />
          <span>{emojiValues.thumbsUp}</span>
          <p>Total number of&nbsp;thumbs-up</p>
        </Analytic>
        <Analytic>
          <EmojiLove />
          <span>{emojiValues.heart}</span>
          <p>Total number of&nbsp;hearts</p>
        </Analytic>
        <Analytic>
          <EmojiClap />
          <span>{emojiValues.clap}</span>
          <p>Total number of&nbsp;claps</p>
        </Analytic>
      </AnalyticsBar>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Analytic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: start;
  svg {
    max-height: 48px;
    max-width: 48px;
  }
  span {
    font-size: 3rem !important;
    font-weight: 700;
    line-height: 3rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 200px;
    text-align: center;
  }
  &:first-of-type {
    grid-column: span 2;
  }
  @media only screen and (min-width: 900px) {
    &:first-of-type {
      grid-column: span 1;
    }
  }
`;

export default AnalyticsEmojiBar;
