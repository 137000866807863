import React from 'react';
import { Speaker } from 'components';
import { CardDeck } from 'styles';

const Speakers = ({ speakers, colors }) => (
  <>
    <CardDeck id="speakers" wider={speakers.length === 4}>
      {speakers.map((speaker) => (
        <Speaker
          key={speaker.id}
          data={speaker}
          colors={colors}
          buttonText="Read Bio"
          popupType="speaker"
          speakers={speakers}
        />
      ))}
    </CardDeck>
  </>
);

export default Speakers;
