import { useStaticQuery, graphql } from 'gatsby';

const useEventData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(src/data/events)/" } }) {
        edges {
          node {
            id
            frontmatter {
              eid
              slug
              hidden
              status
              colors {
                primary
                secondary
                tertiary
              }

              name
              title
              subtitle
              date
              description
              start
              end
              emailDateTime
              startTime
              endTime
              banner {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
                }
              }
              staticBannerImage {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    height: 900
                    quality: 90
                  )
                }
              }
              bannerVideo
              thumb {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
              speakers {
                id
                name
                position
                shortSummary
                summary
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark;
};

export default useEventData;
