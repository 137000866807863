import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';
import defaultThumbnail from 'assets/images/event-11/social-thumbnail.png';

export default ({
  children,
  lang = 'en',
  meta = [],
  pageSpecificTitle,
  pageSpecificSiteTitle,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificThumbnailAlt
}) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            defaultSiteTitle
            defaultDescription
            defaultThumbnailAlt
            siteUrl
            twitterPage
            author
          }
        }
      }
    `}
    render={(data) => {
      const {
        defaultSiteTitle,
        defaultDescription,
        defaultThumbnailAlt,
        siteUrl,
        twitterPage,
        author
      } = data.site.siteMetadata;
      const siteTitle = pageSpecificTitle
        ? pageSpecificTitle.replace(/<[^>]*>?/gm, '')
        : defaultSiteTitle;
      const description = pageSpecificDescription
        ? pageSpecificDescription.replace(/<[^>]*>?/gm, '')
        : defaultDescription;
      const thumbnail = pageSpecificThumbnail || defaultThumbnail;
      const thumbnailAlt = pageSpecificThumbnailAlt || defaultThumbnailAlt;
      const { pathname } = useLocation();
      const url = `${siteUrl}${pathname}`;
      const image = `${siteUrl}${thumbnail}`;
      return (
        <Helmet
          htmlAttributes={{
            lang
          }}
          title={pageSpecificTitle}
          titleTemplate={`%s | ${siteTitle}`}
          link={[
            {
              rel: 'canonical',
              href: url
            }
          ]}
          meta={[
            {
              property: 'og:site_name',
              content: defaultSiteTitle
            },
            {
              name: `description`,
              content: `${description}`
            },
            {
              property: `og:locale`,
              content: 'en_GB'
            },
            {
              property: `og:url`,
              content: url
            },
            {
              property: `og:title`,
              content: `${pageSpecificTitle} | ${defaultSiteTitle}`
            },
            {
              property: `og:description`,
              content: description
            },
            {
              property: `og:type`,
              content: `website`
            },
            {
              property: `og:image`,
              content: image
            },
            {
              property: `og:image:url`,
              content: image
            },
            {
              property: `og:image:secure_url`,
              content: image
            },
            {
              property: `og:image:alt`,
              content: thumbnailAlt
            },
            {
              property: 'og:image:width',
              content: '1200'
            },
            {
              property: 'og:image:height',
              content: '630'
            },
            {
              name: `twitter:card`,
              content: `summary_large_image`
            },
            {
              name: `twitter:image`,
              content: image
            },
            {
              property: 'twitter:image:alt',
              content: thumbnailAlt
            },
            {
              name: `twitter:site`,
              content: twitterPage
            },
            {
              name: `twitter:creator`,
              content: twitterPage
            },
            {
              name: `twitter:title`,
              content: `${pageSpecificTitle} | ${defaultSiteTitle}`
            },
            {
              name: `twitter:description`,
              content: `${description}`
            },
            {
              name: `author`,
              content: author
            }
          ].concat(meta)}>
          {children}
        </Helmet>
      );
    }}
  />
);
