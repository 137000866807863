import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { motion, AnimatePresence } from 'framer-motion';
import { Markup } from 'interweave';

const BulletPoint = ({ top, right, left, bottom, width, content }) => {
  const { theme } = useContext(LocalContext);
  const [open, setOpen] = useState(false);

  return (
    <Container top={top} left={left} right={right} bottom={bottom}>
      <Dot $theme={theme} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} />
      <AnimatePresence>
        {open && (
          <Content
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            width={width}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            $theme={theme}>
            <Markup content={content} />
          </Content>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  bottom: ${({ bottom }) => bottom};
  height: auto;
  left: ${({ left }) => left};
  position: absolute;
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  width: auto;
  z-index: 3;
`;

const Dot = styled(motion.div)`
  animation: pulse 1s infinite;
  background: #ff003e;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 142, 173, 0.1);
  cursor: pointer;
  height: 1.5rem;
  min-width: 1.5rem;
  padding: 1rem;
  position: absolute;
  width: 20px;
  z-index: 2;
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 142, 173, 0.9);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

const Content = styled(motion.div)`
  background: ${({ $theme }) => ($theme.className === 'contrast' ? 'rgb(50,50,50)' : '#fde6ec')};
  border-radius: 5px;
  bottom: ${({ bottom }) => (bottom ? 'calc(100% - 1rem)' : 'initial')};
  color: ${({ $theme }) => $theme.textColor};
  height: auto;
  left: ${({ left }) => (left ? '1rem' : 'initial')};
  max-width: 80vw;
  overflow: hidden;
  padding: 1.5rem;
  padding-bottom: 0;
  padding-top: 1.5rem;
  position: absolute;
  right: ${({ right }) => (right ? '-1rem' : 'initial')};
  text-align: left;
  top: ${({ top, bottom }) => (top && bottom ? 'initial' : top ? '1rem' : 'initial')};
  width: ${({ width }) => (width ? `${width}` : '600px')};
  z-index: 1;
  h4 {
    color: ${({ $theme }) => $theme.textColor};
    font-size: 1.5rem;
    line-height: 1.5em;
    margin-bottom: 1.5em;
  }
  p {
    color: ${({ $theme }) => $theme.textColor};
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 1.5em;
  }
`;

export default BulletPoint;
