import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { SubmittedQuestion, Button, ConfirmDeleteModal } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { SelectQuestionCheckbox } from 'components/Events/Livestream/Interaction/QAndA/SubmittedQuestion';

const QAndA = ({
  eid,
  questionCurrentlyBeingAnsweredLive,
  submittedQuestions,
  textArea,
  setTextArea,
  submitAnonymousQuestion,
  setSubmitAnonymousQuestion,
  selectedQuestion,
  setSelectedQuestion,
  editQuestion,
  setEditQuestion
}) => {
  const { selectedEvent, theme } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const { colors } = selectedEvent;

  const [userQuestions, setUserQuestions] = useState([]);
  const [deleteQuestion, setDeleteQuestion] = useState({
    open: false,
    question: {
      qid: '',
      uid: ''
    }
  });

  const submittedQuestionsRef = useRef();

  const isModerator = user?.isModerator?.includes(eid);

  console.log(submittedQuestions);

  const handleDeleteQuestion = (e) => {
    e.stopPropagation();
    const { qid, uid } = deleteQuestion.question;
    if (firebase) {
      firebase.interaction.qAndA.deleteUserQuestion({ uid, qid }).then(() => {
        setDeleteQuestion({
          open: false,
          question: {}
        });
      });
    }
  };

  const handleCancelDeleteQuestion = (e) => {
    e.stopPropagation();
    setDeleteQuestion({
      open: false,
      question: {}
    });
  };

  const handleTextAreaChange = (e) => {
    e.persist();
    if (e.target.value !== ' ') {
      setTextArea(e.target.value);
    }
  };

  const handleSubmitQuestion = (e) => {
    e.preventDefault();
    if (textArea !== '' && textArea !== ' ') {
      if (editQuestion.edit) {
        if (textArea === editQuestion.question.text) {
          setTextArea('');
          setEditQuestion({
            edit: false,
            question: {
              uid: '',
              qid: '',
              text: ''
            }
          });
        } else {
          firebase.interaction.qAndA
            .editQuestion({ uid: user.uid, qid: editQuestion.question.qid, text: textArea })
            .then(() => {
              setTextArea('');
              setEditQuestion({
                edit: false,
                question: {
                  uid: '',
                  qid: '',
                  text: ''
                }
              });
            });
        }
      } else {
        firebase.interaction.qAndA
          .submitNewQuestion({
            uid: user.uid,
            eid,
            text: textArea,
            name: submitAnonymousQuestion ? '' : user.name
          })
          .then(() => {
            setTextArea('');
            setSubmitAnonymousQuestion(false);
          });
      }
    }
  };

  const handleAnswerThisQuestionLive = () => {
    firebase.interaction.qAndA
      .answerQuestionLive({
        eid,
        text: selectedQuestion.text
      })
      .then(() => {
        // setTextArea('');
        // setSelectedQuestion(null);
      });
  };

  const handleSendAnswer = () => {
    if (textArea !== '' && textArea !== ' ') {
      firebase.interaction.qAndA
        .submitAnswer({
          text: textArea,
          qid: selectedQuestion.qid,
          uid: selectedQuestion.uid
        })
        .then(() => {
          setTextArea('');
          setSelectedQuestion(null);
        });
    }
  };

  return (
    <Wrapper colors={colors} isModerator={isModerator}>
      {isModerator ? (
        <p>Moderator&apos;s Control Panel</p>
      ) : (
        <p style={{ color: user ? 'black' : '#bdbdbd' }}>
          Here you can privately submit questions,
          <br />
          which will be discussed during the livestream.
        </p>
      )}
      <div>
        <SubmittedQuestions colors={colors} tabIndex={0}>
          <AnimatePresence initial={false}>
            {submittedQuestions?.map((submittedQuestion, index) => (
              <SubmittedQuestion
                key={submittedQuestion.qid}
                eid={eid}
                submittedQuestion={submittedQuestion}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                submittedQuestionsRef={submittedQuestionsRef}
                deleteQuestion={deleteQuestion}
                setDeleteQuestion={setDeleteQuestion}
                editQuestion={editQuestion}
                setEditQuestion={setEditQuestion}
                setTextArea={setTextArea}
                index={index}
                submittedQuestions={submittedQuestions}
                colors={colors}
              />
            ))}
          </AnimatePresence>
          {deleteQuestion.open && (
            <ConfirmDeleteModal
              handleDelete={handleDeleteQuestion}
              handleCancel={handleCancelDeleteQuestion}
              type="question"
            />
          )}
        </SubmittedQuestions>
        {isModerator ? (
          <AnswerQuestion colors={colors}>
            <TextArea
              $theme={theme}
              id="answer"
              name="answer"
              colors={colors}
              value={textArea}
              disabled={!selectedQuestion}
              onChange={handleTextAreaChange}
              placeholder="Select a question then type your answer here to send an answer privately to the user, or click 'Answer Live' to answer it publically during the livestream."
            />
            <div>
              <Button
                type="button"
                disabled
                onClick={() => {
                  if (questionCurrentlyBeingAnsweredLive === selectedQuestion?.text) {
                    firebase.interaction.qAndA.stopShowingAnswerLiveOverlay({ eid });
                    setSelectedQuestion(null);
                  } else {
                    handleAnswerThisQuestionLive();
                  }
                }}
                style={{
                  backgroundColor:
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                      ? '#EB556F'
                      : '#fff',
                  border:
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                      ? '1px solid #EB556F'
                      : `1px solid #E5E5E5`,
                  color:
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                      ? '#fff'
                      : '#E5E5E5',
                  marginRight: '10px'
                }}>
                {questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                  ? 'Stop Showing'
                  : 'Answer Live'}
              </Button>
              <Button
                type="button"
                style={{
                  marginLeft: '10px',
                  backgroundColor: `${colors.tertiary}`
                }}
                onClick={handleSendAnswer}
                disabled={!textArea || !selectedQuestion}
                whileTap={{ scale: textArea && selectedQuestion ? 0.9 : 1 }}
                whileHover={{
                  backgroundColor: textArea && selectedQuestion ? colors.tertiary : colors.tertiary,
                  cursor: !textArea || !selectedQuestion ? 'default' : 'pointer'
                }}>
                Send Answer
              </Button>
            </div>
          </AnswerQuestion>
        ) : (
          <SubmitNewQuestion onSubmit={handleSubmitQuestion} colors={colors}>
            <TextArea
              $theme={theme}
              id="question"
              name="question"
              colors={colors}
              value={textArea}
              onChange={handleTextAreaChange}
              placeholder={
                user ? 'Type your question...' : 'Q&A only available for registered users'
              }
              disabled={!user}
            />
            <div>
              <label htmlFor="anonymousCheckbox">
                <AnonymousCheckbox
                  checked={submitAnonymousQuestion}
                  id="anonymousCheckbox"
                  name="anonymousCheckbox"
                  onChange={(e) => setSubmitAnonymousQuestion(e.target.checked)}
                  colors={colors}
                  type="checkbox"
                  disabled={!user}
                  style={{ cursor: user ? 'pointer' : 'default' }}
                />
                Submit Anonymously
              </label>
              <Button
                type="submit"
                disabled={!user || !textArea}
                whileTap={{ scale: textArea ? 0.9 : 1 }}
                whileHover={{
                  backgroundColor: textArea ? colors.secondary : colors.tertiary
                }}>
                {editQuestion.edit ? 'Submit Edit' : 'Submit Question'}
              </Button>
            </div>
          </SubmitNewQuestion>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 0.75rem;

  @media screen and (min-width: 410px) {
    padding: 1.275rem 1.0125rem;
  }

  @media screen and (min-width: 1150px) and (max-width: 1365px) {
    padding: 0.75rem;
  }

  @media screen and (min-width: 1366px) {
    padding: 1.275rem 1.0125rem;
  }

  > p {
    color: #000;
    font-size: 0.875rem;
    font-style: ${({ isModerator }) => (isModerator ? 'normal' : 'italic')};
    font-weight: ${({ isModerator }) => (isModerator ? 600 : 300)};
    line-height: 1.25em;
    margin: 0.5em 0 1em;
    text-align: center;
    ${({ isModerator }) => isModerator && `transform: scale3d(1.125, 1.125, 1.125)`}

    @media screen and (min-width: 410px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      font-size: 0.875rem;
      margin: 0.5em 0 1em;
    }

    @media screen and (min-width: 1366px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.6rem);
    justify-content: space-between;

    @media screen and (min-width: 410px) {
      height: calc(100% - 2.15rem);
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      height: calc(100% - 2.65rem);
    }

    @media screen and (min-width: 1366px) {
      height: calc(100% - 2.2rem);
    }
  }
`;

const SubmittedQuestions = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const TextArea = styled.textarea`
  border: 0.063em solid ${({ colors }) => colors.tertiary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  height: 6.25em;
  margin-top: 0.5em;
  outline-color: ${({ colors }) => colors.tertiary};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.625em;
  resize: none;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }

  &::placeholder {
    color: #c4c4c4;
    font-size: 1rem;
  }
`;

const AnswerQuestion = styled.div`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    display: flex;
    justify-content: flex-end;
    margin-top: 9px;

    @media screen and (min-width: 1150px) {
      justify-content: space-between;
    }

    button {
      border-radius: 0.125rem;
      max-width: 11.875rem;
    }

    button:first-of-type {
      background-color: #fff;
      border: 1px solid ${({ colors }) => colors.secondary};
      color: ${({ colors }) => colors.secondary};

      @media screen and (max-width: 1149px) {
        margin-right: 30px;
      }
    }

    button:last-of-type {
      background-color: ${({ colors }) => colors.secondary};
    }
  }
`;

const AnonymousCheckbox = styled(SelectQuestionCheckbox)`
  border: 1px solid #bdbdbd;
  height: 1rem;
  margin-right: 0.375rem;
  margin-top: unset;
  position: relative;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.tertiary};
    margin-right: 0.375rem;
  }

  &:checked {
    background-color: ${({ colors }) => colors.tertiary};
    border: 2px solid ${({ colors }) => colors.tertiary};
    margin-right: 0.375rem;
  }

  &:checked:before {
    color: ${({ contrast }) => (contrast ? `#000` : `#fff`)};
    font-size: 0.8rem;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

const SubmitNewQuestion = styled.form`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.438rem 0 1.4rem;

    @media screen and (min-width: 1150px) {
      margin: 0.438rem 0 1rem !important;
    }

    @media screen and (min-width: 1354px) {
      margin: 0.438rem 0 1.4rem !important;
    }

    button {
      border-radius: 0.125rem;
      background-color: ${({ colors }) => colors.tertiary};
      max-width: 11.875rem;
    }

    label {
      align-items: center;
      color: #c4c4c4;
      display: flex;
      font-size: 0.875rem;
    }

    @media screen and (min-width: 500px) and (max-width: 1149px) {
      /* justify-content: flex-end; */

      label {
        white-space: nowrap;
      }
    }

    @media screen and (min-width: 500px) and (max-width: 1300px) {
      label {
        margin-right: 1em;
      }
    }
  }
`;

export default QAndA;
