import * as React from 'react';

function SvgBgMoon2(props) {
  return (
    <svg viewBox="0 0 200 219" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M182.389 42.4929L117.716 5.49633C112.346 2.41647 106.252 0.7948 100.048 0.7948C93.8439 0.7948 87.7497 2.41647 82.3799 5.49633L17.6678 42.4929C12.3035 45.5652 7.84768 49.9809 4.74688 55.2976C1.64608 60.6143 0.00912203 66.6455 0 72.7868V146.799C0.00526095 152.941 1.64056 158.973 4.74175 164.291C7.84294 169.608 12.3009 174.023 17.6678 177.092L82.3225 214.108C87.6955 217.179 93.7885 218.795 99.9904 218.795C106.192 218.795 112.285 217.179 117.658 214.108L182.332 177.092C187.697 174.02 192.152 169.604 195.253 164.288C198.354 158.971 199.991 152.94 200 146.799V72.8435C200.003 66.6989 198.378 60.6614 195.287 55.3349C192.197 50.0085 187.749 45.5801 182.389 42.4929Z"
        fill="#025F9F"
      />
    </svg>
  );
}

export default SvgBgMoon2;
