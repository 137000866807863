import React, { useContext } from 'react';
import styled from 'styled-components';
import { H3, P, breakpoints } from 'styles';
import { LocalContext } from 'context';
import { SponsorLogo } from '../../../assets/svgs';

const UnipharSponsor = () => {
  const { theme } = useContext(LocalContext);
  return (
    <SectionWrapper $theme={theme} id="info">
      <Title $theme={theme}>Our sponsor</Title>
      <Content>
        <Description>
          <SponsorLogo />
          <P>
            We&apos;re a trusted global partner to pharma and medtech manufacturers, working to
            improve patient access to medicines in Europe and around the world. We provide
            outsourced and specialised services to our clients. <br />
            <br />
            We&apos;re growing by leveraging the strong relationships we have with 200+ of the
            world&apos;s best known pharmaco-medical manufacturers across multiple geographies,
            enabled by our cutting-edge digital technology and our highly expert teams.
          </P>
        </Description>
        <VideoWrapper $theme={theme}>
          <iframe
            title="Sample"
            src="https://player.vimeo.com/video/676312321?h=ff76d1e814&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          />
        </VideoWrapper>
      </Content>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: ${({ $theme }) => $theme.bg};
  padding: 3rem 1.75rem;
  width: 100%;
  p {
    color: ${({ $theme }) => $theme.textMuted};
    font-family: 'Open Sans', sans-serif;
  }
  ${breakpoints.lg} {
    padding: 6.25rem 9rem;
  }
`;

const Title = styled(H3)`
  color: ${({ $theme }) => $theme.fg};
  margin-bottom: 3rem;
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.25rem;

  ${breakpoints.lg} {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 35% 65%;
  }
`;

const VideoWrapper = styled.div`
  border: 3px solid #a58a4b;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    margin-bottom: 2rem;
    width: 100%;
  }
  ${breakpoints.sm} {
    align-items: center;
    svg {
      max-width: 300px;
    }
  }
  ${breakpoints.lg} {
    align-items: start;
    svg {
      max-width: 300px;
    }
  }
`;

export default UnipharSponsor;
