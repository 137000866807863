import styled, { css } from 'styled-components';

export const FormContainer = styled.form`
  background: #ededed;
  border-radius: 0.625rem;
  color: white;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  max-width: 100%;
  opacity: 0.9;
  padding: 2.5rem 1rem;
  width: 500px;
  h3,
  label,
  input,
  span,
  p,
  textarea {
    color: ${({ $theme }) => $theme.textColor};
  }
  input,
  span textarea {
    border-color: ${({ $theme }) => $theme.textColor};
  }
  input,
  textarea {
    ::placeholder {
      color: ${({ $theme }) => $theme.textColor};
    }
  }
  ,
  button {
    color: white;
  }

  @media (min-width: 1440px) {
    padding: 2.5rem 1.25rem;
    max-width: 1200px;
  }
`;

export const FormLabel = styled.h3`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 1.75rem;

  ${({ hide }) =>
    hide
      ? css`
          height: 1px;
          opacity: 0;
          width: 1px;
        `
      : css`
          margin-bottom: 2rem;
        `}
`;

export const FormInputLabel = styled.label`
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  justify-content: flex-start;
  line-height: 19px;
  overflow: hidden;
  width: 100%;
  ${({ style }) => style}

  ${({ hide }) =>
    hide
      ? css`
          height: 1px;
          opacity: 0;
          width: 1px;
        `
      : css`
          margin-bottom: 0.375rem;
        `}
`;

export const FormInput = styled.input`
  align-items: center;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 3.75rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.6em;
  outline: none;
  padding: 0px 1.25rem;
  width: 100%;

  :focus {
    border: 0.188em solid #fff;
  }

  ::placeholder {
    color: #fff;
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
  }
`;

export const FormChecks = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  position: relative;
`;

export const FormTerms = styled.span`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem;
  a {
    color: ${({ theme }) => theme.textColor};
    text-decoration: underline;
    text-underline-position: under;
    transition: 0.5s;
    &:hover {
      color: ${({ theme }) => theme.fg};
    }
  }
`;

export const FormCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: ${({ $theme }) => `1px solid ${$theme.textColor}`};
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0.75rem;
  margin-top: 0.375rem;
  outline: none;
  padding: 0.625rem;
  position: relative;
  transition: 0.5s;
  width: 1.5rem;
  &:checked {
    background-color: ${({ $theme }) => `${$theme.textColor}`};
    border: ${({ $theme }) => `1px solid ${$theme.textColor}`};
  }
  &:checked:before {
    color: ${({ $theme }) => `${$theme.className === 'contrast' ? 'black' : 'white'}`};
    content: '✓';
    font-size: 1rem;
    height: 1rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-40%, -60%);
    width: 1rem;
  }
  &:checked ${FormTerms}::before {
    color: #41b883;
    content: '\2713';
    display: block;
    left: 0.7rem;
    position: absolute;
    text-align: center;
    top: 0.2rem;
  }
  &:active {
    border: 0.125rem solid #34495e;
  }
`;
export const FileUpload = styled.label`
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 6rem;
  justify-content: center;
  margin-bottom: 1.25rem;
`;

export const ImageUpload = styled.label`
  align-items: center;
  align-self: center;
  border: 1px solid;
  border-radius: 3rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  /* margin-bottom: 2.5rem; */
  margin-bottom: 2rem;
  text-transform: uppercase;
  width: 100%;
`;

export const HiddenFileUpload = styled.input`
  display: none;
`;

export const TextArea = styled.textarea`
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  -webkit-letter-spacing: 0.5px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 0px;
  box-sizing: border-box;
  color: ${({ $theme }) => $theme.textColor};
  font-size: 1rem;

  font-weight: 600;
  height: 10rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
  padding: 0.75rem 1.25rem;
  resize: none;
  width: 100%;
  ::placeholder {
    color: white !important;
    font-style: italic;
  }
  :focus {
    outline: none;
  }
`;
