import React from 'react';
import styled from 'styled-components';
import Emojis from './Emojis';
import RegistrantsAndInteractions from './RegistrantsAndInteractions';
import PieChart from './PieChart';

function AnalyticNumbers({ eid, companies, eventRegistrants }) {
  return (
    <Container id="the-numbers">
      <Title>The Numbers</Title>
      <RegistrantsAndInteractions eid={eid} eventRegistrants={eventRegistrants} />
      <Emojis eid={eid} />
      <PieChart eid={eid} companies={companies} eventRegistrants={eventRegistrants} />
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 3rem;
  text-align: center;
`;

export default AnalyticNumbers;
