import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as D3 from 'd3';
import Pie from '../../../../Shared/PieChart';

const AnalyticsChartSection = ({ companies }) => {
  const [registereeCompanies, setRegistereeCompanies] = useState([]);
  const [chartColors, setChartColors] = useState([
    '#ED6E85',
    '#FF9559',
    '#54A1E5',
    '#6CBEBF',
    '#F8CD6B',
    '#613F75',
    '#003E1F',
    '#FCD581',
    '#FF9F1C',
    '#DC6ACF'
  ]);
  const colors = D3.scaleOrdinal(chartColors);

  useEffect(() => {
    const _chartColors = [];
    registereeCompanies.forEach(() => {
      const newColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      _chartColors.push(newColor);
    });
    setChartColors(_chartColors);
  }, [registereeCompanies]);

  useEffect(() => {
    const companyCounts = companies.reduce((obj, company) => {
      obj[company] = obj[company] + 1 || 1;
      return obj;
    }, {});
    Object.entries(companyCounts).forEach((array) => {
      const [company, count] = array;
      setRegistereeCompanies((currentState) => [...currentState, { label: company, value: count }]);
    });
    return () => setRegistereeCompanies([]);
  }, [companies]);

  return (
    <Wrapper>
      <Title>Registrants Company Chart</Title>
      <ChartWrapper>
        <Legends>
          {registereeCompanies
            .sort((a, b) => b.value - a.value)
            .map((d, index) => (
              <div style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                <Color style={{ background: colors(index), alignSelf: 'center' }} />
                <p
                  style={{
                    fontSize: '12px',
                    margin: '0 0.5rem',
                    padding: '0',
                    width: '100%'
                  }}>
                  {`${d.label} (${d.value})`}
                </p>
              </div>
            ))}
        </Legends>
        <Pie
          data={registereeCompanies.sort((a, b) => b.value - a.value)}
          width={460}
          height={460}
          innerRadius={0}
          outerRadius={230}
          cornerRadius={0}
          colors={colors}
        />
      </ChartWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    width: 100%;
  }
`;

const ChartWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0rem 4.75rem 4.75rem 4.75rem;
  @media only screen and (max-width: 799px) {
    width: 100vw;
  }
`;

const Legends = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem;
  > div {
    margin: 0 1rem 2rem 1rem;
  }
`;

const Color = styled.div`
  height: 0.625rem;
  min-width: 2.5rem;
`;

const Title = styled.p`
  color: #a58a4b;
  font-size: 0.75rem !important;
  margin: 1rem auto;
  text-align: center;
  text-transform: uppercase;
  width: 800px !important;
`;
export default AnalyticsChartSection;
