import React from 'react';
import styled from 'styled-components';

const AnalyticsBar = ({ children, title, padding, height }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Bar padding={padding} height={height}>
      {children}
    </Bar>
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.p`
  color: #a58a4b;
  font-size: 0.75rem !important;
  margin: 1rem auto;
  text-align: center;
  text-transform: uppercase;
  width: 800px !important;
`;

const Bar = styled.div`
  background: rgba(0, 149, 255, 0.1);
  display: flex;
  height: ${(props) => (props.height ? props.height : '200px')};
  justify-content: space-around;
  margin: 2.25rem 7.5rem 4.25rem 7.5rem;
  padding: ${(props) => (props.padding ? props.padding : '0')};
  width: calc(100% - 15rem);
  svg {
    max-height: 48px;
    max-width: 48px;
  }
  @media only screen and (max-width: 820px) {
    flex-direction: column;
    height: 100%;
    margin: 3rem 1rem;
    width: calc(100% - 4rem);
    h4 {
      font-size: 36px;
    }
    p {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 1020px) {
    margin: 0 2rem 3rem 2rem;
    width: calc(100% - 4rem);
  }
`;

export default AnalyticsBar;
