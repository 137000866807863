import * as React from 'react';

function SvgForumLogo(props) {
  return (
    <svg viewBox="0 0 357 179" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.37 178.081H12V145.001H0V137.911H33.36V145.001H21.36L21.37 178.081Z"
        fill="white"
      />
      <path
        d="M76.37 178.081H67.03V160.751H49.53V178.081H40.16V137.921H49.53V153.661H67.03V137.921H76.37V178.081Z"
        fill="white"
      />
      <path
        d="M112.96 178.081H87.52V137.921H112.96V144.921H96.88V153.741H111.88V160.741H96.88V171.091H112.96V178.081Z"
        fill="white"
      />
      <path
        d="M147.47 178.081H138.25V137.921H163.58V144.921H147.47V155.281H162.47V162.231H147.47V178.081Z"
        fill="white"
      />
      <path
        d="M212.32 158.001C212.32 164.668 210.507 169.778 206.88 173.331C203.253 176.884 198.057 178.668 191.29 178.681C184.517 178.681 179.317 176.898 175.69 173.331C172.063 169.764 170.253 164.634 170.26 157.941C170.26 151.274 172.077 146.171 175.71 142.631C179.343 139.091 184.557 137.328 191.35 137.341C198.137 137.341 203.33 139.118 206.93 142.671C210.53 146.224 212.327 151.334 212.32 158.001ZM180.08 158.001C180.08 162.491 181.01 165.861 182.89 168.141C184.77 170.421 187.56 171.541 191.29 171.541C198.757 171.541 202.493 167.028 202.5 158.001C202.507 148.974 198.79 144.451 191.35 144.431C187.617 144.431 184.807 145.571 182.92 147.851C181.033 150.131 180.087 153.514 180.08 158.001Z"
        fill="white"
      />
      <path
        d="M230.88 162.671V178.081H221.48V137.921H234.35C240.35 137.921 244.793 138.921 247.68 140.921C250.567 142.921 252.007 145.938 252 149.971C252.026 152.258 251.267 154.485 249.85 156.281C248.252 158.243 246.155 159.738 243.78 160.611C250.447 169.644 254.78 175.474 256.78 178.101H246.38L235.88 162.671H230.88ZM230.88 155.751H233.88C236.84 155.751 239.023 155.301 240.43 154.401C241.13 153.951 241.697 153.321 242.07 152.576C242.443 151.832 242.609 151.001 242.55 150.171C242.615 149.359 242.446 148.545 242.063 147.825C241.68 147.106 241.1 146.511 240.39 146.111C238.95 145.298 236.717 144.894 233.69 144.901H230.88V155.751Z"
        fill="white"
      />
      <path
        d="M298.29 137.921V163.921C298.35 166.68 297.587 169.395 296.1 171.721C294.554 174.031 292.348 175.822 289.77 176.861C286.662 178.115 283.331 178.721 279.98 178.641C274.3 178.641 269.89 177.308 266.75 174.641C263.61 171.974 262.037 168.351 262.03 163.771V137.891H271.37V162.481C271.37 165.571 272.05 167.851 273.42 169.291C274.79 170.731 277.06 171.461 280.22 171.461C283.38 171.461 285.51 170.731 286.89 169.281C288.27 167.831 288.96 165.541 288.96 162.421V137.891L298.29 137.921Z"
        fill="white"
      />
      <path
        d="M327.82 178.081L317.18 146.581H316.88C317.26 152.994 317.45 157.268 317.45 159.401V178.081H309.08V137.921H321.88L332.34 168.631H332.52L343.61 137.921H356.36V178.081H347.63V159.081C347.63 158.181 347.63 157.141 347.63 155.971C347.63 154.801 347.79 151.681 348.03 146.631H347.76L336.37 178.081H327.82Z"
        fill="white"
      />
      <path
        d="M168.44 91.0009C168.674 97.7519 170.294 104.383 173.2 110.481C174.591 113.448 176.583 116.094 179.05 118.251C180.374 119.349 182.048 119.938 183.768 119.91C185.488 119.883 187.142 119.241 188.43 118.101C191.225 115.541 193.426 112.401 194.88 108.901C197.051 103.896 198.347 98.5546 198.71 93.1109C198.864 91.3512 198.817 89.5799 198.57 87.8309C198.293 86.3751 197.554 85.0475 196.462 84.0457C195.37 83.0439 193.984 82.4214 192.51 82.2709C186.75 81.8959 180.971 81.8959 175.21 82.2709C173.991 82.3383 172.811 82.7272 171.79 83.3982C170.77 84.0691 169.945 84.9981 169.4 86.0909C168.632 87.6071 168.299 89.3069 168.44 91.0009ZM168.44 59.1909C168.453 63.2259 170.064 67.0915 172.92 69.942C175.776 72.7924 179.645 74.3956 183.68 74.4009C187.697 74.3826 191.545 72.7821 194.39 69.9463C197.235 67.1105 198.849 63.2678 198.88 59.2509C198.875 57.2387 198.473 55.2474 197.696 53.3911C196.92 51.5347 195.785 49.8499 194.356 48.4332C192.927 47.0164 191.233 45.8956 189.37 45.135C187.507 44.3744 185.512 43.989 183.5 44.0009C179.501 44.0559 175.683 45.68 172.87 48.5232C170.056 51.3664 168.473 55.2011 168.46 59.2009L168.44 59.1909Z"
        fill="white"
      />
      <path
        d="M168.44 91.0009C168.284 89.2997 168.606 87.5889 169.37 86.0608C169.915 84.9681 170.74 84.0391 171.76 83.3681C172.781 82.6972 173.961 82.3083 175.18 82.2408C180.941 81.8658 186.719 81.8658 192.48 82.2408C193.954 82.3914 195.34 83.0139 196.432 84.0157C197.524 85.0175 198.263 86.3451 198.54 87.8008C198.787 89.5498 198.834 91.3212 198.68 93.0808C198.33 98.533 197.044 103.884 194.88 108.901C193.436 112.402 191.245 115.545 188.46 118.111C187.172 119.251 185.518 119.893 183.798 119.92C182.078 119.948 180.404 119.359 179.08 118.261C176.613 116.104 174.621 113.458 173.23 110.491C170.313 104.392 168.682 97.7574 168.44 91.0009Z"
        fill="#A58A4B"
      />
      <path
        d="M168.46 59.2008C168.483 55.2148 170.066 51.3962 172.869 48.5627C175.673 45.7293 179.475 44.1061 183.46 44.0408C185.472 44.0237 187.468 44.4039 189.333 45.1596C191.198 45.9153 192.895 47.0317 194.328 48.4447C195.76 49.8578 196.9 51.5397 197.681 53.394C198.462 55.2484 198.87 57.2387 198.88 59.2508C198.851 63.2575 197.249 67.0923 194.419 69.9283C191.588 72.7643 187.757 74.3742 183.75 74.4108C179.706 74.4188 175.825 72.8214 172.958 69.9696C170.091 67.1177 168.473 63.2446 168.46 59.2008Z"
        fill="#A58A4B"
      />
      <path
        d="M143.45 102.001C142.949 101.997 142.469 101.8 142.11 101.451L141.69 101.031C136.187 95.5342 131.829 89.0007 128.868 81.8086C125.907 74.6165 124.402 66.9085 124.44 59.1309C124.401 51.3515 125.905 43.6417 128.866 36.4478C131.827 29.2538 136.186 22.7187 141.69 17.2208C147.189 11.7231 153.723 7.37041 160.915 4.41462C168.107 1.45884 175.814 -0.0413422 183.59 0.000866529C191.367 -0.0387194 199.075 1.46465 206.267 4.4239C213.459 7.38315 219.993 11.7395 225.49 17.2409C230.992 22.7396 235.349 29.275 238.308 36.4689C241.267 43.6628 242.77 51.3722 242.73 59.1509C242.77 66.9279 241.266 74.6356 238.307 81.8277C235.348 89.0199 230.991 95.5537 225.49 101.051C225.312 101.23 225.101 101.371 224.869 101.468C224.636 101.565 224.387 101.615 224.135 101.615C223.883 101.615 223.634 101.565 223.401 101.468C223.169 101.371 222.957 101.23 222.78 101.051C222.421 100.689 222.219 100.2 222.219 99.6909C222.219 99.1813 222.421 98.6924 222.78 98.3309C227.92 93.1885 231.99 87.0781 234.753 80.353C237.517 73.6279 238.919 66.4215 238.88 59.1509C238.915 51.8852 237.511 44.6846 234.747 37.9649C231.984 31.2452 227.916 25.1396 222.78 20.0009C217.638 14.8559 211.527 10.782 204.8 8.01503C198.073 5.24801 190.864 3.84285 183.59 3.88087C176.316 3.84285 169.107 5.24801 162.38 8.01503C155.653 10.782 149.542 14.8559 144.4 20.0009C139.255 25.1427 135.181 31.2541 132.414 37.9811C129.647 44.7081 128.242 51.9171 128.28 59.1909C128.243 66.4629 129.649 73.6701 132.416 80.3953C135.183 87.1205 139.256 93.2302 144.4 98.3709L144.8 98.7709C145.158 99.1307 145.36 99.618 145.36 100.126C145.36 100.634 145.158 101.121 144.8 101.481C144.435 101.824 143.951 102.01 143.45 102.001Z"
        fill="white"
      />
      <path
        d="M154.38 90.3909C154.007 90.3862 153.649 90.2396 153.38 89.9809L153.08 89.6709C149.069 85.6686 145.893 80.9101 143.735 75.671C141.578 70.432 140.481 64.8168 140.51 59.1509C140.481 53.4843 141.576 47.8683 143.732 42.6278C145.888 37.3873 149.062 32.6264 153.07 28.6209C157.076 24.6126 161.836 21.4387 167.077 19.2827C172.317 17.1268 177.933 16.0317 183.6 16.0609C189.265 16.0325 194.879 17.128 200.118 19.2839C205.357 21.4398 210.116 24.6134 214.12 28.6209C218.128 32.6264 221.302 37.3873 223.458 42.6278C225.614 47.8683 226.709 53.4843 226.68 59.1509C226.708 64.8158 225.613 70.4302 223.457 75.6689C221.301 80.9077 218.127 85.6668 214.12 89.6709C213.852 89.93 213.493 90.0747 213.12 90.0747C212.747 90.0747 212.388 89.93 212.12 89.6709C211.861 89.4025 211.716 89.0439 211.716 88.6709C211.716 88.2978 211.861 87.9393 212.12 87.6709C215.86 83.9325 218.821 79.4896 220.833 74.5994C222.844 69.7092 223.867 64.4686 223.84 59.1809C223.867 53.8931 222.844 48.6526 220.833 43.7623C218.821 38.8721 215.86 34.4293 212.12 30.6909C208.382 26.9512 203.939 23.9898 199.049 21.9781C194.158 19.9664 188.918 18.9443 183.63 18.9708C178.342 18.9443 173.102 19.9664 168.211 21.9781C163.321 23.9898 158.878 26.9512 155.14 30.6909C151.4 34.4293 148.439 38.8721 146.427 43.7623C144.416 48.6526 143.393 53.8931 143.42 59.1809C143.393 64.4686 144.416 69.7092 146.427 74.5994C148.439 79.4896 151.4 83.9325 155.14 87.6709L155.43 87.9509C155.687 88.2205 155.83 88.5786 155.83 88.9509C155.83 89.3232 155.687 89.6813 155.43 89.9509C155.294 90.0917 155.131 90.2034 154.95 90.279C154.77 90.3547 154.576 90.3928 154.38 90.3909Z"
        fill="white"
      />
      <path
        d="M165.02 79.0508C164.769 79.0447 164.53 78.9448 164.35 78.7708L164.16 78.5808C159.012 73.4231 156.12 66.4333 156.12 59.1458C156.12 51.8583 159.012 44.8685 164.16 39.7108C169.319 34.5679 176.306 31.6799 183.59 31.6799C190.874 31.6799 197.861 34.5679 203.02 39.7108C208.164 44.8711 211.052 51.8599 211.052 59.1458C211.052 66.4317 208.164 73.4205 203.02 78.5808C202.835 78.7523 202.592 78.8476 202.34 78.8476C202.088 78.8476 201.845 78.7523 201.66 78.5808C201.489 78.3959 201.393 78.153 201.393 77.9008C201.393 77.6486 201.489 77.4057 201.66 77.2208C206.449 72.4247 209.139 65.9238 209.139 59.1458C209.139 52.3678 206.449 45.867 201.66 41.0708C196.864 36.2815 190.363 33.5914 183.585 33.5914C176.807 33.5914 170.306 36.2815 165.51 41.0708C160.726 45.8696 158.039 52.3695 158.039 59.1458C158.039 65.9222 160.726 72.4221 165.51 77.2208L165.7 77.4008C165.872 77.5857 165.967 77.8286 165.967 78.0808C165.967 78.333 165.872 78.5759 165.7 78.7608C165.519 78.9408 165.275 79.0446 165.02 79.0508Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgForumLogo;
