const firebaseConfig = {
  apiKey: 'AIzaSyDFE0GOoN380fmtNBgvpJu8UrRLNiC8zNU',
  authDomain: 'ipha-forum-eu.firebaseapp.com',
  databaseURL: 'https://ipha-forum-eu-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'ipha-forum-eu',
  storageBucket: 'ipha-forum-eu.appspot.com',
  messagingSenderId: '527695934147',
  appId: '1:527695934147:web:96caee236699c8c08e2876'
};

export default firebaseConfig;
