import * as React from 'react';

function SvgHelp(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.544 16.696H13.688V18.696H11.544V16.696ZM9 10.904C9 10.3173 9.08533 9.784 9.256 9.304C9.43733 8.824 9.69333 8.41333 10.024 8.072C10.3653 7.73067 10.7707 7.46933 11.24 7.288C11.72 7.096 12.2533 7 12.84 7C13.3307 7 13.784 7.07467 14.2 7.224C14.6267 7.36267 14.9947 7.57067 15.304 7.848C15.624 8.11467 15.8747 8.45067 16.056 8.856C16.2373 9.26133 16.328 9.72 16.328 10.232C16.328 10.6053 16.2853 10.9307 16.2 11.208C16.1253 11.4747 16.0187 11.7093 15.88 11.912C15.752 12.104 15.6027 12.28 15.432 12.44C15.2613 12.5893 15.0907 12.7387 14.92 12.888C14.7173 13.0587 14.5307 13.224 14.36 13.384C14.1893 13.544 14.04 13.7253 13.912 13.928C13.784 14.12 13.6827 14.3493 13.608 14.616C13.544 14.8827 13.512 15.208 13.512 15.592H11.784C11.784 15.1227 11.8053 14.728 11.848 14.408C11.9013 14.0773 11.9813 13.7893 12.088 13.544C12.1947 13.2987 12.3227 13.0853 12.472 12.904C12.632 12.712 12.8187 12.5253 13.032 12.344C13.2027 12.1947 13.3627 12.056 13.512 11.928C13.672 11.8 13.8107 11.6613 13.928 11.512C14.056 11.352 14.152 11.176 14.216 10.984C14.2907 10.792 14.328 10.5627 14.328 10.296C14.328 9.976 14.2693 9.704 14.152 9.48C14.0453 9.24533 13.912 9.05867 13.752 8.92C13.592 8.78133 13.4213 8.68 13.24 8.616C13.0587 8.552 12.8987 8.52 12.76 8.52C12.0987 8.52 11.608 8.73867 11.288 9.176C10.9787 9.60267 10.824 10.1787 10.824 10.904H9Z"
        fill="#025F9F"
      />
      <circle cx="13" cy="13" r="12.5" stroke="#025F9F" />
    </svg>
  );
}

export default SvgHelp;
