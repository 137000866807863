import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { gridContainer, fullGridContainer, breakpoints, H6 } from 'styles';
import { LogoSmall, AgencyxLogo } from 'assets/svgs';

const HubFooter = () => {
  const { theme, contactRef } = useContext(LocalContext);

  return (
    <>
      <GridContainer $theme={theme}>
        <Subscribe $theme={theme}>
          <a href="https://www.ipha.ie" target="blank">
            <LogoSmall width={280} />
          </a>
          <P style={{ marginBottom: '3rem' }}>
            The Forum Hub is brought to you by IPHA, with support from Agency X
          </P>
          <a href="https://www.agencyx.ie" target="blank">
            <AgencyxLogo className="ax-logo" width={180} />
          </a>
        </Subscribe>
        <Contact id="contact" ref={contactRef}>
          <H6 style={{ marginBottom: '0.625rem' }}>Support</H6>
          <ContactDetail $theme={theme} href="mailto:support@iphaforum.ie">
            support@iphaforum.ie
          </ContactDetail>
          <H6 style={{ marginBottom: '0.625rem' }}>Contact</H6>
          <ContactDetail $theme={theme} href="mailto:info@ipha.ie">
            info@ipha.ie
          </ContactDetail>
        </Contact>
      </GridContainer>
      <FooterInfo $theme={theme}>
        <FooterCopyright $theme={theme}>
          Copyright © 2020 - IPHA Forum - Designed and Developed by Agency X{' '}
        </FooterCopyright>
        <FooterTerms $theme={theme}>
          <a href="https://www.ipha.ie/footer-links/terms-and-conditions/" target="blank">
            Terms and Conditions
          </a>
          <a href="https://www.ipha.ie/footer-links/privacy-statement/" target="blank">
            Privacy Policy
          </a>
        </FooterTerms>
      </FooterInfo>
    </>
  );
};

const GridContainer = styled.footer`
  background: rgba(196, 196, 196, 0.2);
  ${fullGridContainer}
  color: ${({ $theme }) => $theme.textMuted};
  grid-row-gap: 0.75rem;
  padding: 2.25rem 1.25rem;
  position: relative;
  ${breakpoints.sm} {
    padding: 40px;
  }
  ${breakpoints.lg} {
    grid-gap: auto;
    padding: 0;
    padding-bottom: 2.25rem;
    padding-top: 3.5rem;
  }
`;

const P = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0px;
  max-width: 280px;
`;

const Subscribe = styled.div`
  color: ${({ $theme }) => $theme.textMuted};
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  svg {
    margin-bottom: 1.875rem;
    max-width: 280px;
    path {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : 'auto')};
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/5;
  }
`;

const Contact = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

const ContactDetail = styled.a`
  align-items: center;
  color: ${({ $theme }) => $theme.textColor};
  cursor: pointer;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  justify-content: flex-start;
  margin-bottom: 2.25rem;
  text-decoration: none;
  transition: 0.5s;
  :visited {
    color: ${({ $theme }) => $theme.textColor};
  }
  svg {
    height: 1.75rem;
    margin-right: 0.75rem;
    min-width: 1.75rem;
    width: 1.75rem;
    circle {
      fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : $theme.fg)};
      transition: 0.5s;
    }
    path {
      stroke: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
      transition: 0.5s;
    }
  }
  &:hover {
    color: ${({ $theme }) => $theme.fg};
    svg {
      circle {
        fill: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
      }
      path {
        stroke: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : $theme.fg)};
      }
    }
  }
`;

const FooterInfo = styled.footer`
  ${fullGridContainer}
  align-items: start;
  background: rgba(189, 189, 189, 0.3);
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding: 1rem 1.25rem;
  ${breakpoints.sm} {
    grid-column: 1/7;
  }
  ${breakpoints.lg} {
    grid-column: 2/13;
    padding: 12px 0px;
  }
`;

const FooterCopyright = styled.p`
  color: ${({ $theme }) => $theme.textColor};
  font-size: 0.75rem;
  grid-column: 1/7;
  line-height: 1.25rem;
  margin-top: 0px;
  ${breakpoints.sm} {
    grid-column: 1/7;
  }
  ${breakpoints.lg} {
    grid-column: 2/7;
    left: 1rem;
  }
`;

const FooterTerms = styled.div`
  display: flex;
  grid-column: 1/7;
  justify-content: flex-start;
  a {
    color: ${({ $theme }) => $theme.textColor};
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-right: 1.25rem;
  }
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

export default HubFooter;
