import * as React from 'react';

function SvgSponsorLogo(props) {
  return (
    <svg viewBox="0 0 299 131" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M88.0607 5.36131C88.1327 6.04796 88.0396 6.7419 87.7891 7.38513C87.5386 8.02837 87.1379 8.60221 86.6207 9.05871C85.4436 9.9419 83.99 10.3747 82.5223 10.2789H81.23V15.3443H78.2393V1.10921H82.7808C84.1744 1.0129 85.558 1.40523 86.6946 2.21861C87.141 2.60805 87.4952 3.09199 87.7315 3.6356C87.9678 4.17921 88.0803 4.76876 88.0607 5.36131ZM81.23 7.61667H82.2269C82.9702 7.66793 83.7097 7.47319 84.3315 7.0622C84.5582 6.86725 84.7363 6.62193 84.8517 6.34592C84.9671 6.06991 85.0165 5.77086 84.9961 5.47234C85.0196 5.18893 84.9823 4.90359 84.8868 4.63578C84.7912 4.36796 84.6397 4.12369 84.4423 3.91927C83.9142 3.51951 83.2547 3.33475 82.5961 3.40181H81.23V7.61667Z"
        fill="#025F9F"
      />
      <path
        d="M102.387 9.68702C102.47 11.2082 101.968 12.7035 100.984 13.8651C100.473 14.3749 99.8599 14.7705 99.1852 15.0255C98.5105 15.2805 97.7895 15.389 97.0697 15.3441C96.1052 15.3611 95.1523 15.1321 94.3005 14.6786C93.5098 14.2115 92.879 13.5153 92.4913 12.6819C92.0304 11.7524 91.8023 10.7245 91.8267 9.68702C91.7414 8.17682 92.2443 6.69236 93.2298 5.54595C93.7391 5.03418 94.3518 4.63747 95.0268 4.38239C95.7019 4.1273 96.4236 4.01987 97.1436 4.06713C98.1081 4.05015 99.061 4.27915 99.9128 4.73263C100.694 5.19518 101.323 5.87623 101.722 6.69213C102.183 7.62166 102.411 8.64951 102.387 9.68702ZM95.0021 9.68702C94.9426 10.5373 95.1352 11.3864 95.5559 12.1274C95.7355 12.4099 95.9884 12.6382 96.2876 12.788C96.5867 12.9377 96.9209 13.0034 97.2543 12.9778C97.5885 12.999 97.9227 12.9358 98.226 12.7936C98.5293 12.6514 98.7919 12.435 98.9897 12.1644C99.3782 11.3992 99.5566 10.5441 99.5066 9.68702C99.5677 8.84836 99.3747 8.01088 98.9528 7.28384C98.7686 7.00814 98.5137 6.78718 98.2149 6.64407C97.916 6.50095 97.5843 6.44083 97.2543 6.4703C96.9189 6.43796 96.5809 6.49647 96.2756 6.63955C95.9704 6.78263 95.7091 7.00515 95.519 7.28384C95.0781 8.00729 94.848 8.83955 94.8544 9.68702H95.0021Z"
        fill="#025F9F"
      />
      <path
        d="M115.753 15.1594L114.94 11.462L113.796 6.6552L111.839 15.2702H108.627L105.525 4.39984H108.479L109.734 9.20642C109.956 10.0568 110.14 11.277 110.362 12.9038C110.4 12.1065 110.511 11.3145 110.694 10.5377V9.72411L112.024 4.51087H115.31L116.602 9.72411C116.578 9.93295 116.578 10.1438 116.602 10.3526C116.602 10.6114 116.602 10.9444 116.602 11.2402C116.602 11.536 116.602 11.8687 116.602 12.1645C116.615 12.4108 116.615 12.6575 116.602 12.9038C116.602 12.4232 116.602 11.7947 116.897 10.9813C117.193 10.1679 117.156 9.61324 117.23 9.31745L118.522 4.51087H121.439L118.3 15.3812L115.753 15.1594Z"
        fill="#025F9F"
      />
      <path
        d="M130.78 15.3443C130.036 15.3867 129.29 15.281 128.587 15.0335C127.884 14.7861 127.236 14.4015 126.682 13.9023C126.173 13.3525 125.78 12.7058 125.526 12.0007C125.273 11.2956 125.163 10.5465 125.205 9.79819C125.122 8.26071 125.608 6.74647 126.571 5.54609C127.053 5.06081 127.63 4.67927 128.265 4.42496C128.9 4.17064 129.58 4.04904 130.263 4.06727C130.938 4.01377 131.616 4.10472 132.253 4.33424C132.889 4.56375 133.47 4.92651 133.956 5.39828C134.412 5.89302 134.763 6.47644 134.985 7.11248C135.208 7.74851 135.297 8.4236 135.248 9.09568V10.5377H128.233C128.219 10.8971 128.277 11.2555 128.404 11.5921C128.531 11.9286 128.724 12.2363 128.971 12.4972C129.229 12.7484 129.536 12.9437 129.873 13.0709C130.209 13.1981 130.568 13.2546 130.928 13.2367C131.55 13.241 132.17 13.1665 132.774 13.0149C133.414 12.87 134.034 12.6465 134.62 12.3494V14.6788C134.095 14.9405 133.536 15.1271 132.959 15.2333C132.239 15.3567 131.508 15.394 130.78 15.3443ZM130.374 6.17481C130.101 6.16664 129.828 6.21476 129.574 6.31653C129.32 6.41831 129.09 6.57147 128.897 6.7663C128.512 7.24879 128.303 7.84924 128.306 8.46718H132.442C132.475 7.85096 132.278 7.24436 131.888 6.7663C131.687 6.57214 131.45 6.4199 131.19 6.31834C130.93 6.21678 130.653 6.16803 130.374 6.17481Z"
        fill="#025F9F"
      />
      <path
        d="M145.697 4.06726H146.731L146.472 6.87732C146.191 6.8336 145.904 6.8336 145.623 6.87732C144.817 6.82928 144.024 7.09395 143.408 7.61662C143.138 7.87991 142.926 8.19702 142.786 8.54729C142.646 8.89755 142.581 9.27338 142.595 9.65037V15.1965H139.642V4.3261H141.894L142.337 6.13779C142.66 5.53694 143.13 5.02796 143.703 4.65874C144.286 4.25062 144.986 4.04312 145.697 4.06726Z"
        fill="#025F9F"
      />
      <path
        d="M155.592 15.3443C154.848 15.3867 154.103 15.281 153.399 15.0335C152.696 14.7861 152.048 14.4015 151.494 13.9022C150.978 13.3574 150.58 12.7114 150.326 12.005C150.072 11.2987 149.967 10.5473 150.017 9.79819C149.915 8.25841 150.404 6.73772 151.383 5.54609C151.866 5.06081 152.442 4.67927 153.077 4.42496C153.712 4.17064 154.392 4.04904 155.075 4.06727C155.75 4.01377 156.428 4.10472 157.065 4.33424C157.701 4.56375 158.282 4.92651 158.768 5.39828C159.224 5.89302 159.575 6.47644 159.797 7.11248C160.02 7.74851 160.109 8.4236 160.06 9.09568V10.5377H153.045C153.022 10.8981 153.076 11.259 153.204 11.5968C153.331 11.9346 153.528 12.2415 153.783 12.4972C154.038 12.7521 154.345 12.9499 154.682 13.0774C155.02 13.2049 155.38 13.2592 155.74 13.2367C156.362 13.241 156.982 13.1665 157.586 13.0149C158.226 12.87 158.847 12.6465 159.432 12.3494V14.6788C158.907 14.9405 158.348 15.1271 157.771 15.2333C157.051 15.355 156.32 15.3923 155.592 15.3443ZM155.186 6.17481C154.913 6.16664 154.64 6.21476 154.386 6.31653C154.132 6.41831 153.902 6.57147 153.709 6.7663C153.327 7.25345 153.108 7.84842 153.082 8.46718H157.365C157.398 7.85096 157.2 7.24436 156.811 6.7663C156.597 6.55813 156.342 6.39775 156.062 6.29577C155.782 6.19379 155.483 6.15262 155.186 6.17481Z"
        fill="#025F9F"
      />
      <path
        d="M167.887 15.3443C167.305 15.3691 166.726 15.2467 166.203 14.9882C165.68 14.7297 165.231 14.3434 164.897 13.8652C164.091 12.6405 163.703 11.188 163.789 9.72418C163.703 8.24873 164.091 6.78444 164.897 5.5461C165.252 5.07159 165.715 4.68958 166.249 4.43219C166.782 4.17479 167.369 4.04968 167.961 4.06727C168.575 4.05 169.184 4.18617 169.732 4.46378C170.28 4.74139 170.75 5.15166 171.1 5.65713C170.96 4.93816 170.886 4.20793 170.878 3.47556V0H173.832V15.1595H171.58L170.989 13.7544C170.661 14.2724 170.2 14.6931 169.655 14.9726C169.109 15.2522 168.499 15.3805 167.887 15.3443ZM168.921 12.9779C169.236 13.0044 169.553 12.9581 169.848 12.8427C170.142 12.7274 170.406 12.546 170.62 12.3124C171.014 11.642 171.207 10.8716 171.174 10.0941V9.76119C171.236 8.89143 171.057 8.02137 170.657 7.24698C170.449 6.98017 170.178 6.76941 169.869 6.63383C169.559 6.49826 169.221 6.44206 168.884 6.47045C168.574 6.46386 168.268 6.53899 167.996 6.68822C167.723 6.83746 167.495 7.05573 167.334 7.321C166.917 8.0636 166.725 8.91123 166.78 9.76119C166.725 10.6112 166.917 11.4588 167.334 12.2014C167.512 12.4534 167.751 12.6565 168.029 12.7922C168.306 12.9278 168.613 12.9917 168.921 12.9779Z"
        fill="#025F9F"
      />
      <path
        d="M192.256 4.06727C192.845 4.05988 193.427 4.19352 193.954 4.45723C194.481 4.72094 194.937 5.10697 195.284 5.58311C196.084 6.79644 196.472 8.23549 196.392 9.68717C196.478 11.1678 196.076 12.6358 195.247 13.8652C194.892 14.3398 194.428 14.722 193.895 14.9794C193.362 15.2368 192.774 15.3619 192.183 15.3443C191.612 15.3631 191.046 15.2495 190.527 15.0121C190.008 14.7748 189.551 14.4202 189.192 13.9763H188.97L188.49 15.1595H186.238V0H189.192V3.6974C189.192 4.17806 189.192 4.88067 189.192 5.87896C189.492 5.3281 189.935 4.86873 190.475 4.54976C191.014 4.23079 191.63 4.06413 192.256 4.06727ZM191.333 6.43344C191.025 6.40493 190.715 6.45445 190.432 6.57719C190.148 6.69994 189.899 6.89199 189.709 7.13595C189.308 7.80252 189.127 8.579 189.192 9.35453V9.68717C189.133 10.5567 189.312 11.4257 189.709 12.2014C189.901 12.4551 190.154 12.6561 190.444 12.7854C190.735 12.9147 191.054 12.9681 191.37 12.9409C191.66 12.943 191.946 12.869 192.198 12.7263C192.451 12.5836 192.662 12.3772 192.81 12.1276C193.22 11.3691 193.412 10.5116 193.364 9.65038C193.418 8.81226 193.226 7.9765 192.81 7.24698C192.653 6.9969 192.435 6.79105 192.177 6.64873C191.919 6.5064 191.628 6.43218 191.333 6.43344Z"
        fill="#025F9F"
      />
      <path
        d="M199.272 4.289H202.521L204.589 10.3896C204.748 11.0086 204.859 11.6391 204.921 12.2753C204.996 11.6351 205.132 11.0036 205.327 10.3896L207.358 4.289H210.533L205.918 16.5644C205.606 17.5882 204.971 18.4832 204.109 19.1156C203.311 19.6818 202.354 19.9795 201.376 19.9659C200.867 19.959 200.359 19.8971 199.863 19.7811V17.4517C200.256 17.4898 200.651 17.4898 201.044 17.4517C201.544 17.4686 202.031 17.2971 202.41 16.9711C202.845 16.5852 203.165 16.086 203.333 15.529V14.9743L199.272 4.289Z"
        fill="#025F9F"
      />
      <path
        d="M107.445 92.7307H98.6574V88.0349C97.5539 89.8005 96.0098 91.2475 94.1774 92.2331C92.345 93.2186 90.2876 93.7088 88.2083 93.655C78.4238 93.655 75.95 88.0719 75.95 79.6788V58.8626H85.1806V77.9781C85.1806 83.5612 86.8052 86.2973 91.0883 86.2973C96.0728 86.2973 98.2144 83.4872 98.2144 76.6101V58.8626H107.445V92.7307Z"
        fill="#025F9F"
      />
      <path
        d="M113.057 58.8627H121.845V63.5952C122.925 61.8475 124.437 60.4084 126.236 59.4174C128.034 58.4263 130.057 57.917 132.109 57.9383C141.894 57.9383 144.368 63.5213 144.368 71.9144V92.7308H135.137V73.6153C135.137 68.0322 133.512 65.2961 129.229 65.2961C124.245 65.2961 122.103 68.1061 122.103 74.9833V92.7308H113.057V58.8627Z"
        fill="#025F9F"
      />
      <path
        d="M159.801 53.6123H150.571V45.9587H159.801V53.6123ZM150.571 58.8628H159.801V92.7309H150.571V58.8628Z"
        fill="#025F9F"
      />
      <path
        d="M165.783 58.8628H174.533V63.1887C175.574 61.5008 177.048 60.1234 178.802 59.2C180.555 58.2765 182.524 57.8409 184.503 57.9385C195.026 57.9385 199.752 66.5164 199.752 76.1666C199.752 85.1883 194.841 93.6553 184.983 93.6553C183.018 93.7022 181.072 93.2607 179.32 92.3703C177.567 91.4798 176.062 90.1683 174.94 88.5528V104.747H165.746L165.783 58.8628ZM190.595 75.9078C190.595 70.5096 188.453 64.8156 182.546 64.8156C176.638 64.8156 174.57 70.2508 174.57 75.9078C174.57 81.5648 176.638 86.6301 182.62 86.6301C188.601 86.6301 190.595 81.4169 190.595 75.9078Z"
        fill="#025F9F"
      />
      <path
        d="M204.146 92.7309H213.339V75.1682C213.339 68.2911 215.481 65.4811 220.502 65.4811C224.785 65.4811 226.41 68.2171 226.41 73.8002V92.9157H235.604V72.0993C235.604 63.7062 233.13 58.1233 223.345 58.1233C221.371 58.1661 219.439 58.7069 217.729 59.6957C216.019 60.6846 214.585 62.0893 213.561 63.7802H213.339V45.9587H204.146V92.7309Z"
        fill="#025F9F"
      />
      <path
        d="M240.921 69.2892C241.437 60.5634 249.191 57.9384 256.687 57.9384C263.37 57.9384 271.456 59.4543 271.456 67.5886V85.1882C271.306 87.71 271.709 90.234 272.637 92.583H263.296C262.94 91.5176 262.717 90.4124 262.631 89.2923C261.162 90.703 259.424 91.8032 257.521 92.5273C255.618 93.2514 253.589 93.5847 251.554 93.5074C245.167 93.5074 240.108 90.2906 240.108 83.3395C240.108 75.6859 245.831 73.8371 251.554 73.0607C257.277 72.2842 262.447 72.3951 262.447 68.6237C262.447 64.8524 259.714 64.039 256.465 64.039C253.216 64.039 250.705 65.481 250.373 69.1414L240.921 69.2892ZM262.225 76.1666C259.84 77.3767 257.218 78.0456 254.545 78.1261C251.702 78.7177 249.117 79.6791 249.117 83.1177C249.117 86.5563 251.776 87.4436 254.767 87.4436C255.794 87.5312 256.828 87.3859 257.792 87.0187C258.755 86.6514 259.624 86.0717 260.334 85.3223C261.043 84.5729 261.575 83.6732 261.889 82.6898C262.204 81.7065 262.293 80.6648 262.151 79.6421L262.225 76.1666Z"
        fill="#025F9F"
      />
      <path
        d="M277.289 92.7309H286.594V77.4606C286.594 71.5078 288.957 66.5902 295.677 66.5902C296.793 66.5961 297.905 66.7077 299 66.9231V58.2711C298.354 58.0691 297.683 57.9573 297.006 57.9384C294.712 57.9781 292.479 58.6791 290.573 59.9579C288.667 61.2368 287.17 63.0389 286.262 65.1482V58.8628H277.511L277.289 92.7309Z"
        fill="#025F9F"
      />
      <path
        d="M3.69227 93.6551L0 92.3979L0.66461 88.7005C1.35415 84.9249 2.69077 81.2974 4.61534 77.9782C7.14353 73.5542 10.6404 69.7618 14.8429 66.886L16.0614 66.0356L16.3199 67.5145C16.6823 69.9396 17.3012 72.3193 18.166 74.6134L18.3875 75.2421L17.8706 75.6488C15.6346 77.5449 13.7368 79.8079 12.2583 82.3411C11.5099 83.6063 10.8914 84.9443 10.4122 86.3343C11.8619 86.5997 13.3323 86.7358 14.806 86.741C17.7158 86.7396 20.6027 86.2264 23.3351 85.2249C28.8592 83.1821 33.4873 79.2545 36.4058 74.1327L36.8858 73.2454H37.5504C38.8048 73.4495 40.0399 73.7587 41.2427 74.1697C42.408 74.6117 43.5428 75.1306 44.6396 75.7228L45.4149 76.1665L45.0088 76.9798C44.7503 77.4605 44.4919 77.9412 44.1965 78.4218C40.2048 85.2814 33.9312 90.5167 26.4736 93.2114C22.7801 94.552 18.8823 95.24 14.9537 95.245C11.1144 95.2555 7.30294 94.5924 3.69227 93.2855"
        fill="#76BC21"
      />
      <path
        d="M34.0428 70.2137H32.9352L32.6767 69.4744C31.7951 67.1078 31.3328 64.6053 31.3105 62.0796V61.0072H32.3813H34.0428C37.9029 60.9985 41.7362 61.6487 45.3781 62.9299C47.3059 63.634 49.171 64.4999 50.9534 65.5181C55.1936 67.9745 58.8558 71.3165 61.691 75.3168C64.5262 79.3171 66.4679 83.8818 67.384 88.7007L68.1225 92.5831L64.4302 93.9141C60.7796 95.2149 56.9329 95.8776 53.058 95.8736C50.0257 95.8787 47.0069 95.4684 44.0858 94.6536C42.1379 94.1395 40.2457 93.4335 38.4366 92.5461L36.849 91.8066L38.2151 90.6604C40.1085 89.128 41.8405 87.4059 43.3843 85.5209L43.9012 84.9295L44.6396 85.1883C45.2439 85.4189 45.8601 85.6163 46.4858 85.7798C48.6016 86.3697 50.7879 86.6682 52.9842 86.6671C54.4078 86.6726 55.829 86.549 57.2303 86.2975C55.1612 80.9167 51.3355 76.3949 46.375 73.4674C45.0823 72.7245 43.7224 72.1055 42.3135 71.6187C39.6579 70.6773 36.8599 70.202 34.0428 70.2137Z"
        fill="#FFA400"
      />
      <path
        d="M36.9964 36.0496C40.4032 38.9595 43.2012 42.5161 45.229 46.514C47.2569 50.5118 48.4745 54.8719 48.8116 59.3432V61.0811L47.1501 60.4896L46.2271 60.1197C44.2327 59.4169 42.1822 58.885 40.0979 58.5299H39.3225V57.7533C38.5798 53.5001 36.7478 49.5125 34.0056 46.1804C33.1188 47.2633 32.3165 48.4131 31.6057 49.6192C29.4624 53.3299 28.3174 57.534 28.2826 61.8206V62.0424C28.2835 66.3919 29.4298 70.6642 31.6057 74.4286L32.1964 75.39L31.6795 75.9815C30.8172 77.0236 29.8659 77.9886 28.8365 78.8656C27.9485 79.5732 27.0107 80.2158 26.0303 80.7883L25.1073 81.3427L24.5534 80.4554L23.6673 79.0504C20.6825 73.8946 19.1154 68.0389 19.1258 62.0794V61.7466C19.1746 55.8651 20.7389 50.096 23.6673 44.9975C25.6006 41.6469 28.0991 38.657 31.0518 36.1606L34.0426 33.6464L36.9964 36.1606"
        fill="#00AEEF"
      />
      <path
        d="M198.533 121.46C198.533 127.339 195.838 130.333 190.226 130.333C186.866 130.333 182.841 128.965 182.841 125.046H185.795C185.795 127.191 188.49 127.967 190.41 127.967C194.102 127.967 195.801 125.157 195.801 121.127V119.944C195.28 120.963 194.477 121.811 193.489 122.388C192.501 122.965 191.368 123.246 190.226 123.197C189.081 123.185 187.951 122.931 186.911 122.452C185.87 121.974 184.942 121.281 184.186 120.42C183.431 119.558 182.864 118.547 182.525 117.452C182.185 116.358 182.079 115.204 182.213 114.065C182.074 112.884 182.183 111.687 182.533 110.55C182.882 109.414 183.465 108.363 184.243 107.465C185.022 106.567 185.979 105.841 187.054 105.335C188.128 104.829 189.297 104.553 190.484 104.526C191.565 104.486 192.635 104.745 193.579 105.273C194.523 105.801 195.305 106.578 195.838 107.52V104.969H198.607L198.533 121.46ZM195.616 113.621C195.616 110.441 194.213 107.151 190.521 107.151C186.829 107.151 185.241 110.256 185.241 113.732C185.241 117.208 186.423 120.609 190.299 120.609C194.176 120.609 195.616 116.986 195.616 113.621Z"
        fill="#025F9F"
      />
      <path
        d="M202.152 123.013H205.106V114.952C205.106 110.404 206.804 107.557 211.567 107.557V104.414C210.162 104.303 208.76 104.655 207.574 105.416C206.387 106.177 205.482 107.306 204.995 108.63V104.932H202.226L202.152 123.013Z"
        fill="#025F9F"
      />
      <path
        d="M220.133 104.526C221.348 104.497 222.554 104.729 223.672 105.206C224.79 105.682 225.793 106.392 226.614 107.288C227.436 108.185 228.057 109.246 228.435 110.402C228.814 111.558 228.942 112.782 228.81 113.991C228.942 115.199 228.815 116.42 228.436 117.575C228.057 118.729 227.435 119.788 226.613 120.682C225.791 121.575 224.787 122.281 223.669 122.754C222.552 123.226 221.346 123.453 220.133 123.419C218.922 123.447 217.719 123.216 216.604 122.742C215.489 122.267 214.488 121.561 213.667 120.669C212.846 119.776 212.224 118.72 211.843 117.568C211.462 116.417 211.33 115.197 211.456 113.991C211.324 112.782 211.452 111.558 211.831 110.402C212.209 109.246 212.83 108.185 213.652 107.288C214.473 106.392 215.477 105.682 216.594 105.206C217.712 104.729 218.919 104.497 220.133 104.526ZM220.133 120.794C223.235 120.794 225.708 118.354 225.708 113.991C225.708 109.628 223.235 107.151 220.133 107.151C217.032 107.151 214.558 109.591 214.558 113.991C214.558 118.391 217.105 120.794 220.133 120.794Z"
        fill="#025F9F"
      />
      <path
        d="M246.422 104.932H243.505V115.137C243.593 115.872 243.518 116.618 243.287 117.322C243.056 118.025 242.673 118.669 242.166 119.209C241.659 119.748 241.04 120.17 240.353 120.444C239.666 120.718 238.927 120.837 238.188 120.794C237.691 120.846 237.188 120.787 236.716 120.619C236.244 120.452 235.816 120.181 235.462 119.827C235.108 119.473 234.838 119.044 234.671 118.571C234.503 118.099 234.444 117.595 234.496 117.097V104.858H231.542V116.764C231.542 120.461 233.019 123.345 237.708 123.345C238.892 123.409 240.07 123.133 241.103 122.55C242.136 121.967 242.982 121.101 243.542 120.055V122.938H246.311L246.422 104.932Z"
        fill="#025F9F"
      />
      <path
        d="M250.262 104.969H253.216V107.409C253.796 106.441 254.637 105.656 255.643 105.146C256.649 104.636 257.779 104.42 258.902 104.525C260.088 104.542 261.257 104.809 262.333 105.31C263.409 105.81 264.367 106.533 265.144 107.43C265.922 108.327 266.501 109.379 266.844 110.516C267.187 111.653 267.287 112.85 267.136 114.028C267.287 115.194 267.19 116.38 266.85 117.506C266.51 118.632 265.936 119.673 265.165 120.561C264.394 121.449 263.443 122.162 262.376 122.655C261.31 123.148 260.15 123.408 258.976 123.419C257.859 123.458 256.751 123.222 255.746 122.732C254.742 122.242 253.873 121.513 253.216 120.609V129.927H250.262V104.969ZM258.57 107.151C254.619 107.151 253.105 110.589 253.105 113.954C253.105 117.318 254.693 120.794 258.754 120.794C262.816 120.794 264.108 117.355 264.108 113.806C264.108 110.256 262.373 107.151 258.57 107.151Z"
        fill="#025F9F"
      />
    </svg>
  );
}

export default SvgSponsorLogo;
