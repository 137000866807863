/* eslint-disable react/no-danger */
import React, { useState, useContext, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Markup } from 'interweave';
import styled from 'styled-components';
import { Arrow } from 'assets/svgs';
import { Link } from 'gatsby';
import { hexToRGB } from 'utils';
import { useEventData } from 'hooks';
import { LocalContext } from 'context';

export default ({ toggleNav }) => {
  const { edges: events } = useEventData();
  const { mobile, theme } = useContext(LocalContext);
  const [expanded, setExpanded] = useState(false);
  const [pastEvent24Expanded, setPastEvent24Expanded] = useState(false);
  const [pastEvent23Expanded, setPastEvent23Expanded] = useState(false);
  const [pastEvent22Expanded, setPastEvent22Expanded] = useState(false);

  const dropdownToggle = () => setExpanded((currentValue) => !currentValue);

  const year24Toggle = () => setPastEvent24Expanded((currentValue) => !currentValue);
  const year23Toggle = () => setPastEvent23Expanded((currentValue) => !currentValue);
  const year22Toggle = () => setPastEvent22Expanded((currentValue) => !currentValue);

  const futureEvents = events
    .filter(({ node }) => node.frontmatter.status === 'future' && !node.frontmatter.hidden)
    .sort((a, b) => b.node.frontmatter.eid - a.node.frontmatter.eid);
  const pastEvents = events
    .filter(({ node }) => node.frontmatter.status === 'past')
    .sort((a, b) => b.node.frontmatter.eid - a.node.frontmatter.eid);

  const year24PastEvents = pastEvents.filter(({ node }) =>
    node.frontmatter.startTime.includes('2024')
  );
  const year23PastEvents = pastEvents.filter(({ node }) =>
    node.frontmatter.startTime.includes('2023')
  );
  const year22PastEvents = pastEvents.filter(({ node }) =>
    node.frontmatter.startTime.includes('2022')
  );

  useEffect(() => {
    if (expanded) {
      setPastEvent24Expanded(true);
      setPastEvent23Expanded(false);
      setPastEvent22Expanded(false);
    }
  }, [expanded]);

  return (
    <Dropdown expanded={expanded}>
      <Heading
        id="event-selection"
        onClick={() => {
          if (mobile) {
            dropdownToggle();
          }
        }}
        onMouseEnter={() => {
          if (!mobile) {
            dropdownToggle();
          }
        }}
        onMouseLeave={() => setExpanded(false)}
        expanded={expanded}
        $theme={theme}>
        <DownArrow $expanded={expanded} $theme={theme} stroke="#025F9F" />
        <span>Event Selection</span>
        <ContentContainer expanded={expanded} $theme={theme}>
          <AnimatePresence initial={false}>
            {expanded && (
              <Content
                initial="exit"
                animate={expanded ? 'enter' : 'exit'}
                exit="exit"
                variants={{
                  enter: {
                    opacity: 1,
                    rotateX: 0,
                    transition: {
                      duration: 0.5
                    },
                    display: 'block'
                  },
                  exit: {
                    opacity: 0,
                    rotateX: -15,
                    transition: {
                      duration: 0.5,
                      delay: 0.3
                    },
                    transitionEnd: {
                      display: 'none'
                    }
                  }
                }}>
                {futureEvents.length > 0 && (
                  <>
                    <H4>Future Events</H4>
                    {futureEvents.map((event, i) => (
                      <StyledLink
                        to={`/events/${event.node.frontmatter.slug}`}
                        key={i}
                        className="dropdown-link"
                        onClick={() => {
                          setExpanded(false);
                          toggleNav();
                        }}
                        $theme={theme}>
                        <Markup content={event.node.frontmatter.name} noHtml />
                        <p>{event.node.frontmatter.date.split(', ')[1].split(' <span>')[0]}</p>
                      </StyledLink>
                    ))}
                  </>
                )}
                {pastEvents.length > 0 && (
                  <>
                    <H4>Past Events</H4>
                    {year24PastEvents.length > 0 ? (
                      <>
                        <H3
                          onClick={() => {
                            year24Toggle();
                          }}>
                          2024 Events <DownArrow $expanded={pastEvent24Expanded} $theme={theme} />
                        </H3>
                        <AnimatePresence>
                          <motion.div
                            initial="exit"
                            animate={pastEvent24Expanded ? 'enter' : 'exit'}
                            exit="exit"
                            variants={{
                              enter: {
                                opacity: 1,
                                rotateX: 0,
                                transition: {
                                  duration: 0.5
                                },
                                display: 'block'
                              },
                              exit: {
                                opacity: 0,
                                rotateX: -15,
                                transition: {
                                  duration: 0.5,
                                  delay: 0.3
                                },
                                transitionEnd: {
                                  display: 'none'
                                }
                              }
                            }}>
                            {pastEvent24Expanded &&
                              year24PastEvents.map((event, i) => (
                                <StyledLink
                                  to={`/events/${event.node.frontmatter.slug}/livestream`}
                                  key={i}
                                  $theme={theme}
                                  onClick={() => {
                                    setExpanded(false);
                                    toggleNav();
                                  }}>
                                  <Markup content={event.node.frontmatter.name} noHtml />
                                  <p>
                                    {event.node.frontmatter.date.split(', ')[1].split(' <span>')[0]}
                                  </p>
                                </StyledLink>
                              ))}
                          </motion.div>
                        </AnimatePresence>
                        <H3
                          onClick={() => {
                            year23Toggle();
                          }}>
                          2023 Events <DownArrow $expanded={pastEvent23Expanded} $theme={theme} />
                        </H3>
                        <AnimatePresence>
                          <motion.div
                            initial="exit"
                            animate={pastEvent23Expanded ? 'enter' : 'exit'}
                            exit="exit"
                            variants={{
                              enter: {
                                opacity: 1,
                                rotateX: 0,
                                transition: {
                                  duration: 0.5
                                },
                                display: 'block'
                              },
                              exit: {
                                opacity: 0,
                                rotateX: -15,
                                transition: {
                                  duration: 0.5,
                                  delay: 0.3
                                },
                                transitionEnd: {
                                  display: 'none'
                                }
                              }
                            }}>
                            {pastEvent23Expanded &&
                              year23PastEvents.map((event, i) => (
                                <StyledLink
                                  to={`/events/${event.node.frontmatter.slug}/livestream`}
                                  key={i}
                                  $theme={theme}
                                  onClick={() => {
                                    setExpanded(false);
                                    toggleNav();
                                  }}>
                                  <Markup content={event.node.frontmatter.name} noHtml />
                                  <p>
                                    {event.node.frontmatter.date.split(', ')[1].split(' <span>')[0]}
                                  </p>
                                </StyledLink>
                              ))}
                          </motion.div>
                        </AnimatePresence>
                        <H3
                          onClick={() => {
                            year22Toggle();
                          }}>
                          2022 Events <DownArrow $expanded={pastEvent22Expanded} $theme={theme} />
                        </H3>
                        <AnimatePresence>
                          <motion.div
                            initial="exit"
                            animate={pastEvent22Expanded ? 'enter' : 'exit'}
                            exit="exit"
                            variants={{
                              enter: {
                                opacity: 1,
                                rotateX: 0,
                                transition: {
                                  duration: 0.5
                                },
                                display: 'block'
                              },
                              exit: {
                                opacity: 0,
                                rotateX: -15,
                                transition: {
                                  duration: 0.5,
                                  delay: 0.3
                                },
                                transitionEnd: {
                                  display: 'none'
                                }
                              }
                            }}>
                            {pastEvent22Expanded &&
                              year22PastEvents.map((event, i) => (
                                <StyledLink
                                  to={`/events/${event.node.frontmatter.slug}/livestream`}
                                  key={i}
                                  $theme={theme}
                                  onClick={() => {
                                    setExpanded(false);
                                    toggleNav();
                                  }}>
                                  <Markup content={event.node.frontmatter.name} noHtml />
                                  <p>
                                    {event.node.frontmatter.date.split(', ')[1].split(' <span>')[0]}
                                  </p>
                                </StyledLink>
                              ))}
                          </motion.div>
                        </AnimatePresence>
                        <br />
                      </>
                    ) : (
                      <>
                        {pastEvents.map((event, i) => (
                          <StyledLink
                            to={`/events/${event.node.frontmatter.slug}/livestream`}
                            key={i}
                            $theme={theme}
                            onClick={() => {
                              setExpanded(false);
                              toggleNav();
                            }}>
                            <Markup content={event.node.frontmatter.name} noHtml />
                            <p>{event.node.frontmatter.date.split(', ')[1].split(' <span>')[0]}</p>
                          </StyledLink>
                        ))}
                        <br />
                      </>
                    )}
                  </>
                )}
              </Content>
            )}
          </AnimatePresence>
        </ContentContainer>
      </Heading>
    </Dropdown>
  );
};

const DownArrow = styled(Arrow)`
  height: auto;
  margin-left: 0;
  margin-right: 0.5rem;
  min-width: 1rem;
  transform: ${({ $expanded }) => ($expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition-duration: 0.5s;
  width: 1rem;
  path {
    stroke: ${({ $theme }) => $theme.textMuted};
    transition: 0.25s;
  }
  @media (min-width: 1150px) {
    height: auto;
    min-width: 0.75rem;
    width: 0.75rem;
    path {
      stroke: ${({ $theme }) => $theme.textMuted};
    }
  }
`;

const Content = styled(motion.section)`
  bottom: 15px;
  position: relative;
  @media screen and (min-width: 768px) {
    bottom: 0;
  }

  @media (min-width: 1150px) {
    width: 100%;
    a {
      text-align: left;
    }

    padding-top: 1.25rem;
  }
`;

const ContentContainer = styled.div`
  height: auto;
  text-align: center;
  width: 100%;

  @media (min-width: 1150px) {
    border: 1px solid ${({ $theme }) => $theme.fg};
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
    display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
    height: auto;
    left: 0.6rem;
    position: absolute;
    text-align: left;
    top: 3.5rem;
    width: 412px;
  }
`;

const Heading = styled(motion.div)`
  background: white;
  border: none;
  color: ${({ $theme }) => $theme.textMuted};
  font-family: 'Open Sans', tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 3rem;
  outline: none;
  text-align: center;
  transition: 0.5s;
  width: 100%;
  &:hover {
    color: #a58a4b;
  }
  &:hover ${DownArrow} {
    path {
      stroke: #a58a4b;
    }
  }

  > span {
    cursor: pointer;
  }

  @media (min-width: 1150px) {
    align-items: center;
    background: transparent;
    color: ${({ expanded }) => (expanded ? '#A58A4B' : '#025F9F')};
    display: flex;
    font-size: 0.875rem;
    height: 100%;
    line-height: 1.5rem;
    padding-left: 1em;
    text-align: left;
    width: 180px;
    &:hover {
      color: #a58a4b;
    }
    &:hover ${DownArrow} {
      path {
        stroke: #a58a4b;
      }
    }
  }
`;

const Dropdown = styled.div`
  height: auto;
  overflow: scroll;
  position: relative;
  width: 100%;
  @media (min-width: 1150px) {
    align-items: center;
    display: flex;
    grid-column: 3/6;
    height: 100%;
    margin-left: 1rem;
    overflow: visible;
    position: relative;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  background-color: transparent;
  color: #025f9f;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  height: auto;
  justify-content: center;
  line-height: 1.325em;
  margin-bottom: 0.25rem;
  padding: 0.5rem 1.25rem;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  p {
    font-size: 0.75rem;
    padding: 0;
  }
  &:hover {
    background-color: ${({ $theme }) => hexToRGB({ color: $theme.fg, alpha: 0.5 })};
  }

  @media (min-width: 1150px) {
    align-items: flex-start;
    justify-content: flex-start;
    p {
      font-size: 0.875rem;
    }
  }
`;

const H4 = styled.h4`
  color: ${({ color }) => color};
  font-size: 0.75rem;
  height: 2.4325rem;
  text-transform: uppercase;
  @media (min-width: 1150px) {
    font-size: 0.875rem;
    padding: 0rem 1.5rem;
    margin-left: -0.25rem;
    height: unset;
  }
`;

const H3 = styled(motion.h3)`
  color: ${({ color }) => color};
  cursor: pointer;
  font-size: 0.625rem;
  height: 2rem;
  margin-left: 0.625rem;
  text-transform: uppercase;
  svg {
    height: 0.5rem;
    width: auto;
  }
  @media (min-width: 1150px) {
    font-size: 0.75rem;
    margin-top: 1rem;
    padding: 0rem 1.5rem;
    margin-left: -0.25rem;
    height: unset;
  }
`;
