import React, { useContext } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Modal, SpeakerPopup } from 'components';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import { useIntersection } from 'hooks';
import { breakpoints } from 'styles';
import { Markup } from 'interweave';
import {
  BgMoon1,
  BgMoon2,
  BrokenCircle,
  FbIcon,
  EmailIcon,
  LinkedinIcon,
  TwitterIcon
} from 'assets/svgs';

const Speaker = ({ data, buttonText, speakers, colors }) => {
  const { theme } = useContext(LocalContext);
  const speakerImage = getImage(data.image);
  const [elRef, observerEntry] = useIntersection({ threshold: 0 });
  return (
    <>
      {data.name !== 'Roisin Adams' ? (
        <Modal
          trigger={
            <StyledCard
              $theme={theme}
              colors={colors}
              ref={elRef}
              initial={{ opacity: 0 }}
              animate={observerEntry.isIntersecting ? { opacity: 1 } : { opacity: 0 }}>
              <CardImgContainer>
                <>
                  <SVG>
                    <Circle1 $theme={theme} colors={colors} />
                  </SVG>
                  <SVG>
                    <Circle2 $theme={theme} colors={colors} />
                  </SVG>
                </>
                <CardImg image={speakerImage} alt={data.name} />
              </CardImgContainer>
              <CardTitle $theme={theme}>{data.name}</CardTitle>
              <CardSubtitle $theme={theme}>{data.position}</CardSubtitle>
              <CardShortSummary $theme={theme}>
                <Markup content={data.shortSummary} />
              </CardShortSummary>
              <CardFooter>
                <div style={{ alignSelf: 'center' }}>
                  <CardButton $theme={theme} hover>
                    {buttonText}
                  </CardButton>
                </div>
              </CardFooter>
            </StyledCard>
          }
          modalContent={
            <SpeakerPopup
              startPoint={
                data.name === 'Mihai Rotaru' ? 2 : data.name === 'David McCarthy' ? 1 : data.id - 1
              }
              speakers={speakers.filter((speaker) => speaker.name !== 'Roisin Adams')}
              colors={colors}
            />
          }
        />
      ) : (
        <StyledCard
          $theme={theme}
          colors={colors}
          ref={elRef}
          initial={{ opacity: 0 }}
          animate={observerEntry.isIntersecting ? { opacity: 1 } : { opacity: 0 }}>
          <CardImgContainer>
            <>
              <SVG>
                <Circle1 $theme={theme} colors={colors} />
              </SVG>
              <SVG>
                <Circle2 $theme={theme} colors={colors} />
              </SVG>
            </>
            <CardImg image={speakerImage} alt={data.name} />
          </CardImgContainer>
          <CardTitle $theme={theme}>{data.name}</CardTitle>
          <CardSubtitle $theme={theme}>{data.position}</CardSubtitle>
          <CardShortSummary $theme={theme}>
            <Markup content={data.shortSummary} />
          </CardShortSummary>
        </StyledCard>
      )}
    </>
  );
};

const CardImgContainer = styled(motion.div)`
  background: #fff;
  filter: none;
  left: 50%;
  max-width: 300px;
  overflow: hidden;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  position: relative;
  transform: translateX(-50%);
  transition-duration: 1s;
`;

const SVG = styled(motion.div)`
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: 1s;
  width: auto;
  svg {
    path {
      transition: 0.5s;
    }
  }
  @media (min-width: 1150px) {
    opacity: 0;
  }
`;

const Circle1 = styled(BgMoon1)`
  height: 60%;
  left: 2%;
  position: absolute;
  top: 30%;
  transition-duration: 1s;
  width: 60%;
  /* path {
    fill: ${({ $theme, colors }) =>
    $theme.className === 'contrast' ? $theme.fg : colors.primary} !important;
  } */
`;
const Circle2 = styled(BgMoon2)`
  bottom: 30%;
  height: 60%;
  position: absolute;
  right: 2%;
  transition-duration: 1s;
  width: 60%;
  /* path {
    fill: ${({ $theme }) =>
    $theme.className === 'contrast' ? $theme.textColor : $theme.bg} !important;
  } */
`;

const CardImg = styled(GatsbyImage)`
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 1.25rem;
  object-fit: cover;
  object-position: center;
  transition: 0.5s;
  ${breakpoints.lg} {
    filter: grayscale(100%);
  }
`;

const CardTitle = styled.h3`
  color: ${({ $theme }) => $theme.textMuted} !important;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
`;

const CardSubtitle = styled.p`
  color: ${({ $theme }) => $theme.fg} !important;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  margin: 0 auto;
  max-width: 50%;
  text-align: center;
`;

const CardShortSummary = styled.p`
  color: ${({ $theme }) => $theme.textColor} !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem;
  margin: 0 auto 0.375rem auto;
  max-width: 340px;
  text-align: center;
`;

const CardButton = styled.div`
  align-items: center;
  background-color: transparent;
  color: ${({ $theme }) => $theme.textMuted};
  cursor: pointer;
  display: flex;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.5s;
  width: 100%;
  ${breakpoints.lg} {
    justify-content: center;
  }
`;

const CardFooter = styled.div`
  margin-top: auto;
`;

const StyledCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  transition-duration: 0.375s;
  width: 100%;
  &:hover ${CardImg} {
    box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
    filter: none;
  }
  &:hover ${CardButton} {
    color: ${({ $theme }) => $theme.fg};
  }
  ${breakpoints.lg} {
    &:hover ${SVG} {
      opacity: 1 !important;
    }
    &:hover ${Circle1} {
      left: 0%;
    }
    &:hover ${Circle2} {
      right: 0%;
    }
  }
  p,
  h3 {
    color: ${({ $theme }) => $theme.textColor};
  }
`;

export default Speaker;
