import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc
} from 'firebase/firestore';

function ChatFactory(firebase) {
  const subscribeToEventComments = ({ eid, snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'events', eid, 'comments'),
        orderBy('pinned.status', 'desc'),
        orderBy('pinned.timestamp', 'asc'),
        orderBy('timestamp', 'desc'),
        limit(100)
      ),
      snapshot
    );

  const submitEventComment = async ({
    eid,
    avatarUrl,
    name,
    text,
    profession,
    company,
    socials,
    uid
  }) =>
    addDoc(collection(firebase.fsdb, 'events', eid, 'comments'), {
      avatarUrl,
      name,
      text,
      timestamp: serverTimestamp(),
      profession,
      company,
      socials,
      uid,
      pinned: {
        status: false,
        timestamp: 0
      }
    });

  const editEventComment = async ({ eid, cid, updatedText }) =>
    updateDoc(doc(firebase.fsdb, 'events', eid, 'comments', cid), {
      text: updatedText
    });

  const deleteEventComment = async ({ eid, cid }) =>
    deleteDoc(doc(firebase.fsdb, 'events', eid, 'comments', cid));

  const pinEventComment = async ({ eid, cid }) =>
    updateDoc(doc(firebase.fsdb, 'events', eid, 'comments', cid), {
      pinned: {
        status: true,
        timestamp: serverTimestamp()
      }
    });

  const unpinEventComment = async ({ eid, cid }) =>
    updateDoc(doc(firebase.fsdb, 'events', eid, 'comments', cid), {
      pinned: {
        status: false,
        timestamp: 0
      }
    });

  return {
    subscribeToEventComments,
    submitEventComment,
    editEventComment,
    deleteEventComment,
    pinEventComment,
    unpinEventComment
  };
}

export default ChatFactory;
